import { Store, iNotification } from "react-notifications-component";

export const notify = (options: iNotification) => {
  Store.addNotification({
    message: options.message,
    type: options.type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 9000,
      onScreen: true,
      showIcon: true,
    },
  });
};
