import React, { useEffect } from "react";
import logo from "../assets/DC-Logo's/dc-logo-1024.png";
import About from "../components/About/About";
import Footer from "../components/Footers/Footer";
import Navbar from "../components/Navbars/AuthNavbar";

interface Props {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const AboutPage: React.FC<Props> = ({ setCurrentLocation, pathname }) => {
  useEffect(() => {
    setCurrentLocation("/about");
  }, []);

  return (
    <React.Fragment>
      <Navbar pathname={pathname} />
      <div className="sm:pt-10 mt-9">
        <div className="flex flex-wrap justify-center text-center sm:mb-10">
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex justify-center w-auto">
              <img
                src={logo}
                alt="Logo"
                className="img-fluid max-w-full"
                style={{
                  height: "10rem",
                  width: "10rem",
                }}
              />
            </div>
            <h2 className="text-3xl font-semibold">About Us</h2>
            <span className="leading-4 text-gray-600">
              A body of believers with 1 vision; raising leaders that transform
              society.
            </span>
          </div>
        </div>
        <About />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default AboutPage;
