import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyByJuvt1j1LY_UHV0feamK40hJLgjLcgTg",
  authDomain: "dominioncity-web.firebaseapp.com",
  projectId: "dominioncity-web",
  storageBucket: "dominioncity-web.appspot.com",
  messagingSenderId: "960216535421",
  appId: "1:960216535421:web:1360167d5d3ceabe563c14",
  measurementId: "G-RBS46CL3X6",
};

firebase.initializeApp(firebaseConfig);
const projectStorage = firebase.storage();
const auth = firebase.auth();
const db = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, auth, db, timestamp, firebase };
