import React, { useEffect } from "react";
import logo from "../assets/img/gmtv.png";
// import ReactHlsPlayer from "react-hls-player";
import Navbar from "../components/Navbars/AuthNavbar";
import Footer from "../components/Footers/Footer";

interface LivePageProps {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const LivePage: React.FC<LivePageProps> = ({
  setCurrentLocation,
  pathname,
}) => {
  useEffect(() => {
    setCurrentLocation("/gmtv");
  }, []);

  return (
    <React.Fragment>
      <Navbar pathname={pathname} />
      <div className='sm:pt-10 mt-12 mb-16'>
        <div className='flex flex-wrap justify-center text-center sm:mb-5'>
          <div className='w-full lg:w-6/12 px-4'>
            <div className='flex justify-center mt-3 mb-3'>
              <img
                src={logo}
                alt='Logo'
                className='img-fluid max-w-full'
                style={{
                  height: "10rem",
                  width: "10rem",
                }}
              />
            </div>
            {/* <h2 className="text-3xl font-semibold">Contact Us</h2> */}
          </div>
        </div>
        <div className='w-full flex justify-center'>
          {/* <ReactHlsPlayer
            url="https://livecdn.live247stream.com/neema/tv/playlist.m3u8"
            autoplay={true}
            controls={true}
            width="100%"
            height="auto"
          /> */}
          <iframe
            title='GMTV'
            src='https://iframe.viewmedia.tv?channel=160'
            width='640'
            height='360'
            frameBorder='0'
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

// frameborder='0'
// allowfullscreen
// webkitallowfullscreen
// mozallowfullscreen

export default LivePage;
