import React from "react";
import DeePee from "../../assets/img/Dee_pee2.jpeg";

const About = () => {
  return (
    <div className='container px-4 mx-auto mb-9'>
      <div className='flex sm:flex-row flex-col w-full'>
        <div className=' sm:w-1/2 w-full px-4 flex flex-wrap'>
          <div className='pt-4 w-full px-4 text-center'>
            <div className='relative flex flex-col min-w-0 break-words border border-blue-900 w-full mb-8 shadow-lg  bg-white '>
              <div className='px-4 py-5 flex-auto '>
                <div className=' text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-900'>
                  <i className='fas fa-praying-hands'></i>
                  {/* <i class="fas fa-praying-hands"></i> */}
                </div>
                <h6 className='text-xl font-semibold text-blue-900 uppercase'>
                  Core Values
                </h6>
                <p className='mt-2 mb-4 text-blue-900'>
                  Explore the values that embody the vision of Dominion City
                  Church. Our values reflect our mission to transform the total
                  man so we can impact our communities.
                </p>
              </div>
              <div className='px-4 py-5 flex-auto '>
                <div className=' text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-900'>
                  <i className='fas fa-praying-hands'></i>
                </div>
                <h6 className='text-xl font-semibold text-blue-900 uppercase'>
                  Leadership Institute
                </h6>
                <p className='mt-2 mb-4 text-blue-900'>
                  We strongly believe in leadership and transformation. We are
                  committed to raising and developing sons and daughters in
                  Christ, who will transform society at large, through various
                  spheres of life
                </p>
              </div>
            </div>
          </div>
          <div className='pt-6 w-full  px-4 text-center cursor-pointer'>
            <div className='relative flex flex-col min-w-0 break-words w-full mb-8 shadow-lg  bg-blue-900 '>
              <div className='px-4 py-5 flex-auto '>
                <div className=' text-blue-900 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white'>
                  <i className='fas fa-praying-hands'></i>
                </div>
                <h6 className='text-xl font-semibold text-white uppercase'>
                  Our Beliefs
                </h6>
                <p className='mt-2 mb-4 text-white'>
                  Discover how we view God, Jesus, the Bible, man, and many
                  significant aspects of our faith. Our beliefs are shaped by
                  biblical truths and our decisions as a church are guided by
                  Scripture.
                </p>
              </div>
              <div className='px-4 py-5 flex-auto '>
                <div className=' text-blue-900 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white'>
                  <i className='fas fa-praying-hands'></i>
                </div>
                <h6 className='text-xl font-semibold text-white uppercase'>
                  Ministries
                </h6>
                <p className='mt-2 mb-4 text-white'>
                  Ministries designed to meet the specific needs and desires of
                  our members and the body of Christ at large, while fulfilling
                  its mission to raise leaders that transform society
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='sm:w-1/2 w-full px-4 flex-1'>
          <div className='text-sm block my-4 p-3 text-gray-800  border border-blue-900'>
            <img src={DeePee} alt='Pee' />
          </div>
          <h5 className='text-blue-900 font-semibold'>Dr. David Ogbueli </h5>
          <p className='mb-5'>
            Dr. David Ogbueli is the founder of the New Covenant Family
            Ministries and the Senior Pastor of Dominion City, International. He
            is also the President of the Dominion Leadership Institute, and a
            Senior Facilitator in the Institute of National Transformation.
          </p>
          <p className='mb-5'>
            Dr. David Ogbueli is a minister of the Gospel, and an icon of
            transformational leadership. His passion for Personal Transformation
            and National Transformation has produced various initiatives and
            ministries causing a stir among youth, governments, corporate
            institutions, the media and other stakeholders both in Africa and
            globally.
          </p>
          <p className='mb-5'>
            He is a Management and Government Consultant who speaks nationally
            and internationally in organizations, government houses, embassies
            and churches. He is a widely read author and a social reformer. Dr
            David Ogbueli is married to Pastor Sarah Ogbueli and they have four
            children.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
