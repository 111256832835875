import React, { useEffect } from "react";
import Accomodation from "../components/Accomodation/Accomodation";
import Footer from "../components/Footers/Footer";
import Navbar from "../components/Navbars/AuthNavbar";

interface AccomodationPageProps {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const AccomodationPage: React.FC<AccomodationPageProps> = ({
  setCurrentLocation,
  pathname,
}) => {
  useEffect(() => {
    setCurrentLocation("/accomodation");
  }, []);

  return (
    <React.Fragment>
      <Navbar pathname={pathname} />
      <div className="sm:pt-10 mt-9">
        <Accomodation />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default AccomodationPage;
