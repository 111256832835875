import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Honour from "../components/Accounts/Honour";
import Offerings from "../components/Accounts/Offerings";
import Partnership from "../components/Accounts/Partnership";
import Projects from "../components/Accounts/Projects";
import AdminTab from "../components/Admin/AdminTab";
import Modal from "../components/Admin/Modal";
import PrayerRequests from "../components/Admin/PrayerRequests";
import Testimony from "../components/Admin/Testimony";
import AdminLogin from "../components/Auth/AdminLogin";
import Footer from "../components/Footers/Footer";
import Navbar from "../components/Navbars/AuthNavbar";
import Sidebar from "../components/Sidebar/Sidebar";
import PartnershipForm from "../components/Forms/Partnership";
import MissionsForm from "../components/Forms/MissionsForm";
import Pledge from "../components/Forms/Pledge";
import MailList from "../components/Forms/MailList";
import BirthdayGrid from "../components/birthday/BirthdayGrid";
import HolyGhostConference from "../components/Forms/HolyGhostConference";
import ChurchGrowthData from "../components/Forms/ChurchGrowthData";
import FormSubmissions from "../components/Forms/FormSubmissions";

interface AdminPageProps {
  setCurrentLocation: (location: string) => void;
  isAuthenticated: {
    admin: boolean;
    testify: boolean;
    prayer: boolean;
  };
  setAuthState: (
    state: boolean,
    location: "lagos" | "enugu" | "admin" | "testify" | "prayer"
  ) => void;
  logout: () => void;
  pathname: string;
}

const AdminPage: React.FC<AdminPageProps> = ({
  setCurrentLocation,
  isAuthenticated,
  setAuthState,
  logout,
  pathname,
}) => {
  const [selectedImg, setSelectedImg] = useState<string | null>(null);
  const [currentNav, setNav] = useState("Testimonies");

  useEffect(() => {
    setCurrentLocation("/admin");
  }, []);

  return (
    <div>
      {isAuthenticated.prayer || isAuthenticated.testify ? (
        <Navbar pathname={pathname} />
      ) : null}
      <div className='sm:pt-10 w-full md:w-11/12 ml-auto mr-auto px-4 mb-12'>
        {isAuthenticated.admin ||
        isAuthenticated.prayer ||
        isAuthenticated.testify ? (
          <React.Fragment>
            {isAuthenticated.admin ? (
              <React.Fragment>
                <Sidebar setNav={setNav} />
                <div className='relative md:ml-64'>
                  <div className='py-4'>
                    <h1 className='font-bold text-2xl'>{currentNav}</h1>
                  </div>
                  <div>
                    <Switch>
                      <Route
                        exact
                        path='/admin/testimony'
                        render={() => (
                          <Testimony setSelectedImg={setSelectedImg} />
                        )}
                      />
                      <Route
                        exact
                        path='/admin/prayer-request'
                        render={() => <PrayerRequests />}
                      />
                      <Route exact path='/admin/honour' component={Honour} />
                      <Route
                        exact
                        path='/admin/partnership'
                        component={Partnership}
                      />
                      <Route
                        exact
                        path='/admin/projects'
                        component={Projects}
                      />
                      <Route
                        exact
                        path='/admin/offerings'
                        component={Offerings}
                      />
                      <Route
                        exact
                        path='/admin/forms/partnership'
                        component={PartnershipForm}
                      />
                      <Route
                        exact
                        path='/admin/missions'
                        component={MissionsForm}
                      />

                      <Route
                        exact
                        path='/admin/mail-list'
                        component={MailList}
                      />

                      <Route exact path='/admin/pledge' component={Pledge} />
                      <Route
                        exact
                        path='/admin/birthday'
                        component={BirthdayGrid}
                      />

                      <Route
                        exact
                        path='/admin/holy-ghost'
                        component={HolyGhostConference}
                      />

                      <Route
                        exact
                        path='/admin/church-growth'
                        component={ChurchGrowthData}
                      />
                      <Route
                        exact
                        path='/admin/volunteer'
                        component={() => (
                          <FormSubmissions type='Volunteering' />
                        )}
                      />
                      <Route
                        exact
                        path='/admin/new-convert'
                        component={() => (
                          <FormSubmissions type='Give your life to Christ' />
                        )}
                      />
                      <Route
                        exact
                        path='/admin/counselling'
                        component={() => <FormSubmissions type='Counselling' />}
                      />
                      <Route
                        exact
                        path='/admin/feedback'
                        component={() => <FormSubmissions type='Feedback' />}
                      />
                      <Redirect from='/admin' to='/admin/testimony' />
                    </Switch>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className='flex justify-end pt-9'>
                  <button
                    className='bg-red-600 text-white active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-red-700 outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150'
                    onClick={logout}
                  >
                    Logout
                  </button>
                </div>
                <AdminTab
                  selectedImg={selectedImg}
                  setSelectedImg={setSelectedImg}
                  isAuthenticated={isAuthenticated}
                />
              </React.Fragment>
            )}
            {selectedImg && (
              <Modal
                selectedImg={selectedImg}
                setSelectedImg={setSelectedImg}
              />
            )}
          </React.Fragment>
        ) : (
          <AdminLogin setAuthState={setAuthState} />
        )}
      </div>
      {isAuthenticated.prayer || isAuthenticated.testify ? <Footer /> : null}
    </div>
  );
};

export default AdminPage;
