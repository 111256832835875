import React, { useState, useMemo } from "react";
import testimonyImage from "../../assets/cm_2_4.jpeg";
import Select from "react-select";
import countryList from "react-select-country-list";
import { db, projectStorage, timestamp } from "../../firebase/firebase.utils";
import { notify } from "../../utils/notification";
import { iNotification } from "react-notifications-component";
import { getFileType } from "../../utils";

const Testimony: React.FC = () => {
  const [country, setCountry] = useState<any>("");
  const [fileError, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setData] = useState({
    fullName: "",
    email: "",
    testimony: "",
    contact: "",
  });
  const [filesUrl, setUrl] = useState<{ url: string; type: string }[]>([]);
  const options = useMemo(() => countryList().getData(), []);

  const types = ["image", "video", "pdf"];

  const changeHandler = (value: any) => {
    setCountry(value);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selected: FileList | null = null;

    const { files } = e.target;

    if (files) {
      selected = files;
    }

    if (selected && selected.length > 5) {
      setError("You can only upload maximum of 5 files at once");
      return;
    } else {
      if (selected && selected.length) {
        handleUplaod(selected);
      }
    }
  };

  const handleUplaod = (files: FileList) => {
    if (files && files.length) {
      let count = 0;
      const uploads: { url: string; type: string }[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const type = getFileType(file);
        if (types.includes(type)) {
          setError("");
          count++;
          const isLast = count === files.length;
          if (file) {
            const storageRef = projectStorage.ref(file.name);

            storageRef.put(file).on(
              "state_changed",
              (snap) => {
                let percentage =
                  (snap.bytesTransferred / snap.totalBytes) * 100;
                setProgress(percentage);
              },
              (err) => {
                if (err.message) {
                  notify({
                    message: `${err.message}`,
                    type: "danger",
                  } as iNotification);
                } else {
                  notify({
                    message: `Error uploading image. Please try again`,
                    type: "danger",
                  } as iNotification);
                }
              },
              async () => {
                const url = await storageRef.getDownloadURL();
                uploads.push({ url, type: getFileType(file) });
                if (!isLast) {
                  setProgress(0);
                }
                if (isLast) {
                  notify({
                    message: `File(s) uploaded successfully`,
                    type: "success",
                  } as iNotification);
                }
              }
            );
          } else {
            return;
          }
        } else {
          setError("Please select a file that is either image, video or pdf");
          break;
        }
      }

      setUrl(uploads);
    } else {
      return;
    }

    // references
  };

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    setData({ ...formData, [name]: value });
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!country) {
      notify({
        message: `All form fields are required`,
        type: "danger",
      } as iNotification);
      return;
    }

    try {
      setIsSaving(true);
      const createdAt = timestamp();
      await db.collection("testimony").add({
        country: country ? country.label : "",
        ...formData,
        file: filesUrl,
        createdAt,
      });
      notify({
        message: `Testimony sent successfully. God bless you!`,
        type: "success",
      } as iNotification);
      setIsSaving(false);
      setData({
        fullName: "",
        email: "",
        testimony: "",
        contact: "",
      });
      setCountry("");
      setUrl([]);
    } catch (error: any) {
      if (error.message) {
        notify({
          message: `${error.message}`,
          type: "danger",
        } as iNotification);
      } else {
        notify({
          message: `Error performing operation. Please try again`,
          type: "danger",
        } as iNotification);
      }
      setIsSaving(false);
    }
  };

  const { fullName, email, testimony, contact } = formData;

  const uploadProgress = () => (
    <div className='relative pt-1'>
      <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200'>
        <div
          style={{ width: `${progress}%` }}
          className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500'
        ></div>
      </div>
    </div>
  );

  const saving = (message: string) => {
    return (
      <div className='text-sm text-center text-gray-400'>{message}...</div>
    );
  };

  return (
    <section className='relative sm:py-20 bg-white'>
      <div
        className='bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20'
        style={{ transform: "translateZ(0)" }}
      >
        <svg
          className='absolute bottom-0 overflow-hidden'
          xmlns='http://www.w3.org/2000/svg'
          preserveAspectRatio='none'
          version='1.1'
          viewBox='0 0 2560 100'
          x='0'
          y='0'
        >
          <polygon
            className='text-white fill-current'
            points='2560 0 2560 100 0 100'
          ></polygon>
        </svg>
      </div>
      <div className='container mx-auto sm:px-4'>
        <div className='items-center flex sm:flex-nowrap flex-wrap'>
          <div className='w-full h-full ml-auto mr-auto sm:pt-0 pt-5'>
            <img
              alt='Testimony'
              className='max-w-full shadow-lg'
              src={testimonyImage}
            />
          </div>

          <div className='w-full ml-auto mr-auto sm:px-4 mb-5'>
            <div className=''>
              <div className='relative flex flex-col min-w-0 break-words w-full  shadow-lg  bg-blue-900'>
                <form onSubmit={handleSave}>
                  <div className='flex-auto p-5 lg:p-10'>
                    <h4 className='text-2xl text-white font-semibold'>
                      Have a Testimony ?
                    </h4>
                    <p className='leading-relaxed mt-1 mb-4 text-gray-300'>
                      Tell us what the Lord have done for you.
                    </p>
                    {isSaving ? saving("Sending testimony") : null}
                    <div className='relative w-full mb-3 mt-8'>
                      <label
                        className='block uppercase text-gray-300 text-xs font-bold mb-2'
                        htmlFor='full-name'
                      >
                        Full Name
                      </label>
                      <input
                        id='full-name'
                        type='text'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                        placeholder='Full Name'
                        required
                        onChange={onChange}
                        name='fullName'
                        value={fullName}
                      />
                    </div>
                    <div className='relative w-full mb-3 mt-8'>
                      <label
                        className='block uppercase text-gray-300 text-xs font-bold mb-2'
                        htmlFor='country'
                      >
                        Country
                      </label>

                      <Select
                        options={options}
                        value={country}
                        isClearable
                        isSearchable
                        placeholder='Select country'
                        onChange={changeHandler}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            // primary25: "#9fa6b2",
                            primary: "#1a56db",
                          },
                        })}
                      />
                    </div>

                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-300 text-xs font-bold mb-2'
                        htmlFor='email'
                      >
                        Email
                      </label>
                      <input
                        type='email'
                        id='email'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                        placeholder='Email'
                        required
                        onChange={onChange}
                        name='email'
                        value={email}
                      />
                    </div>

                    <div className='relative w-full mb-3 '>
                      <label
                        className='block uppercase text-gray-300 text-xs font-bold mb-2'
                        htmlFor='contact'
                      >
                        Contact(Mobile)
                      </label>
                      <input
                        type='text'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                        placeholder='Contact number'
                        name='contact'
                        id='contact'
                        required
                        value={contact}
                        onChange={onChange}
                      />
                      <small className='text-gray-300 text-xs'>
                        Please add country code e.g (+234)
                      </small>
                    </div>

                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-300 text-xs font-bold mb-2'
                        htmlFor='message'
                      >
                        Testimony
                      </label>
                      <textarea
                        rows={4}
                        cols={80}
                        id='message'
                        className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full'
                        placeholder='Type a message...'
                        required
                        onChange={onChange}
                        value={testimony}
                        name='testimony'
                      />
                    </div>
                    {progress ? uploadProgress() : null}
                    {fileError ? (
                      <small className='text-red-400 text-xs'>
                        {fileError}
                      </small>
                    ) : null}
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-gray-300 text-xs font-bold mb-2'
                        htmlFor='picture'
                      >
                        File (Optional)
                      </label>

                      <input
                        id='picture'
                        type='file'
                        multiple
                        className='px-2 py-2 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full'
                        placeholder='Choose file...'
                        onChange={handleFileChange}
                      />

                      {/* <button
                          className="bg-white text-blue-900 active:bg-gray-300 text-sm font-bold uppercase px-6 py-2 rounded shadow hover:shadow-lg outline-none hover:bg-gray-300 focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ml-2"
                          type="button"
                          // onClick={handleUplaod}
                        >
                          Upload
                        </button> */}

                      <small className='text-gray-300 text-xs'>
                        Upload image, pdf or 1 minute video of your testiomy
                        (e.g Before and After, Doctors report etc)
                      </small>
                    </div>
                    <div className='text-center mt-6'>
                      <button
                        className='bg-white text-blue-900 active:bg-gray-300 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none hover:bg-gray-300 focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                        type='submit'
                        style={{ backgroundColor: "#b0940c", color: "#fff" }}
                      >
                        Send Testimony
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimony;
