import React from "react";
import { Popup, LoadPanel } from "devextreme-react";

const PaymentConfrimation = () => {
  let urlSearchParams = new URLSearchParams(window.location.search);
  let message = urlSearchParams.get("message");

  return (
    <div>
      <Popup
        visible
        dragEnabled={false}
        closeOnOutsideClick={false}
        showCloseButton={false}
        showTitle={true}
        title='Payment Confirmation'
        width={300}
        height={280}
      >
        <div className='flex justify-center items-start'>
          {message ? (
            <>
              {message === "verified" ? (
                <p>Payment successful</p>
              ) : (
                <p>Payment sent, Please contact the admin to verify payment.</p>
              )}
            </>
          ) : (
            <LoadPanel visible />
          )}
        </div>
      </Popup>
    </div>
  );
};

export default PaymentConfrimation;
