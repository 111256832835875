import React, { useEffect } from "react";
import Footer from "../components/Footers/Footer";
import Navbar from "../components/Navbars/AuthNavbar";
import PrayerRequest from "../components/Prayer/PrayerRequest";

interface Props {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const PrayerPage: React.FC<Props> = ({ setCurrentLocation, pathname }) => {
  useEffect(() => {
    setCurrentLocation("/prayer");

    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Navbar pathname={pathname} />
      <div className="sm:mt-0 mt-14">
        <PrayerRequest />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default PrayerPage;
