import React from "react";
import { Link } from "react-router-dom";
import linkTree from "../../assets/linktree.png";

export default function Footer() {
  return (
    <>
      <footer className='relative bg-gray-300 pt-8 pb-6' id='links'>
        <div
          className='bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20'
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className='absolute bottom-0 overflow-hidden'
            xmlns='http://www.w3.org/2000/svg'
            preserveAspectRatio='none'
            version='1.1'
            viewBox='0 0 2560 100'
            x='0'
            y='0'
          >
            <polygon
              className='text-gray-300 fill-current'
              points='2560 0 2560 100 0 100'
            ></polygon>
          </svg>
        </div>
        <div className='container mx-auto px-4'>
          <div className='flex flex-wrap text-center lg:text-left'>
            <div className='w-full lg:w-6/12 px-4'>
              <h4 className='text-3xl font-semibold'>Let's keep in touch!</h4>
              <h5 className='text-lg mt-0 mb-2 text-gray-700'>
                Follow us on social media for upto date events.
              </h5>
              <div className='mt-6 lg:mb-0 mb-6 flex'>
                <a
                  className='flex bg-white text-red-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
                  href='https://www.youtube.com/c/DominionCity'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fab fa-youtube'></i>
                </a>

                <a
                  className='flex bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
                  href='https://web.facebook.com/DominionCity'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fab fa-facebook-square'></i>
                </a>
                <a
                  className=' flex bg-white text-blue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
                  href='https://www.twitter.com/thedominioncity'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fab fa-twitter'></i>
                </a>
                <a
                  className='flex bg-white text-red-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
                  href='https://www.instagram.com/thedominioncity'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fab fa-instagram'></i>
                </a>
                <a
                  className='flex bg-white text-green-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
                  href='https://api.whatsapp.com/send?phone=2348103526864'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fab fa-whatsapp'></i>
                </a>

                <Link
                  className='flex bg-white text-gray-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
                  to='/contact'
                >
                  <i className='fas fa-envelope'></i>
                </Link>
                <a
                  className='flex bg-white  shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2'
                  href='https://www.linktr.ee/dominioncity'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={linkTree} alt='linktree' className='w-5' />
                </a>
              </div>
              <div className='mt-9 text-sm text-gray-600 font-semibold '>
                <a
                  href='tel:+2348035508230'
                  className='text-blue-600 underline'
                >
                  +234-8035508230
                </a>
                {"   "}
                <a
                  href='tel:+2348103526864'
                  className='text-blue-600 underline'
                >
                  +234-8103526864
                </a>{" "}
                <br />
                Dominion City Church Abuja HQ <br />
                112 Oladipo Diya Street, <br />
                Beside Midel Center, <br /> Gudu District, Abuja
                <br /> Nigeria
              </div>
            </div>
            <div className='w-full lg:w-6/12 px-4'>
              <div className='flex flex-wrap items-top mb-6'>
                <div className='w-full lg:w-4/12 lg:mt-0 mt-2 px-4 ml-auto'>
                  <span className='block uppercase text-blue-900 text-sm font-semibold mb-2'>
                    Useful Links
                  </span>
                  <ul className='list-unstyled'>
                    <li>
                      <Link
                        className='text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm'
                        to='/about'
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        className='text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm'
                        to='/give'
                      >
                        Give
                      </Link>
                    </li>
                    <li>
                      <a
                        className='text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm'
                        href='https://t.me/dominionmandate1'
                      >
                        Devotional
                      </a>
                    </li>
                    <li>
                      <Link
                        className='text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm capitalize'
                        to='/gmtv'
                      >
                        GMTV
                      </Link>
                    </li>
                    <li>
                      <a
                        className=' text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm capitalize'
                        href='https://www.youtube.com/channel/UCLrBzDNqm1pCxFjFqy3yQPA/live?sub_confirmation=1'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Video on Demand
                      </a>
                    </li>
                    <li>
                      <Link
                        to='/livestream'
                        className=' text-red-700 hover:text-red-600 font-semibold block pb-2 text-sm capitalize'
                      >
                        Livestream
                      </Link>
                    </li>
                    <li>
                      <a
                        className='text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm capitalize'
                        href='https://www.dominionmandateradio.com/'
                      >
                        Dominon Mandate Radio
                      </a>
                    </li>
                    <li>
                      <a
                        className='text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm capitalize'
                        href='https://t.me/dominioncityglobal'
                      >
                        Join DC Global Community
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='w-full lg:w-4/12 px-4'>
                  <span className='block uppercase text-blue-900 text-sm font-semibold mb-2'>
                    Other Resources
                  </span>
                  <ul className='list-unstyled'>
                    <li>
                      <Link
                        className='text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm'
                        to='/privacy'
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        className='text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm'
                        to='/contact'
                      >
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className='my-6 border-gray-400' />
          <div className='flex flex-wrap items-center md:justify-between justify-center'>
            <div className='w-full md:w-4/12 px-4 mx-auto text-center'>
              <div className='text-sm text-gray-600 font-semibold py-1'>
                Copyright © {new Date().getFullYear()} Dominion City
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
