import React, { useEffect } from "react";
import Navbar from "../components/Navbars/AuthNavbar";
import Footer from "../components/Footers/Footer";
import Projects from "../components/BuildingProject/Projects";

interface BuildingProjectProps {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const BuildingProjects: React.FC<BuildingProjectProps> = ({
  setCurrentLocation,
  pathname,
}) => {
  useEffect(() => {
    setCurrentLocation("/church-building-projects");
  }, [setCurrentLocation]);

  return (
    <React.Fragment>
      <Navbar pathname={pathname} />
      <Projects />
      <Footer />
    </React.Fragment>
  );
};

export default BuildingProjects;
