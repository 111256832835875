import React, { useEffect } from "react";
import Footer from "../components/Footers/Footer";
import Partnership from "../components/Give/Partnership";
import Navbar from "../components/Navbars/AuthNavbar";

interface PartnershipPageProps {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const PartnershipPage: React.FC<PartnershipPageProps> = ({
  setCurrentLocation,
  pathname,
}) => {
  useEffect(() => {
    setCurrentLocation("/give/partnership");
  }, []);

  const searchParams = new URLSearchParams(window.location.search);

  const isWebView = searchParams.get("webview");

  return (
    <React.Fragment>
      {!isWebView ? <Navbar pathname={pathname} /> : null}
      <div className='sm:pt-10 mt-14 mb-16'>
        <div className='flex flex-wrap justify-center text-center sm:mb-10'>
          <div className='w-full lg:w-6/12 px-4'>
            <h2 className='text-3xl font-semibold sm:mb-0 mb-3'>Partnership</h2>
          </div>
        </div>
        <Partnership />
      </div>
      {!isWebView ? <Footer /> : null}
    </React.Fragment>
  );
};

export default PartnershipPage;
