import React from "react";
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <section className='sm:pb-20 mt-10 '>
      <div className='flex flex-wrap justify-center text-center '>
        <div className='w-full lg:w-6/12 px-4'>
          <h2 className='text-4xl font-semibold'>Camp Meeting 2024</h2>
          {/* <small>Beyond Pentecost</small> */}
          {/* <p className=" text-xl leading-relaxed m-4 text-gray-600">
            Two locations, one event
          </p> */}
        </div>
      </div>
      <div className='container mx-auto px-4'>
        <div className='flex flex-wrap justify-center'>
          {/* <div className='lg:pt-12 pt-6 lg:w-4/12 w-full px-4 text-center cursor-pointer'>
            <div className='relative h-auto flex flex-col min-w-0 break-words border border-blue-900 w-full mb-8 shadow-lg  bg-white '>
              <div className='px-4 py-5 flex-auto '>
                <div className=' text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-900'>
                  <i className='fas fa-map-marker-alt'></i>
                </div>
                <h6 className='text-xl font-semibold text-blue-900 uppercase'>
                  Abuja
                </h6>
                <hr className=' my-3' />
                <div className='mt-3'>
                  <div className='flex flex-col'>
                    <span className='font-semibold'>Morning sessions</span>
                    <span>Registration required</span>
                    <small className='mt-3'>
                      <strong>Price</strong> <br />
                      <strong>Workers:</strong> ₦10,000 <br />{" "}
                      <strong>Students:</strong> ₦5,000 <br />{" "}
                      <strong>Foreign:</strong> $100 USD <br />
                      <strong>Account details</strong> <br />
                      David Ogbueli Ministry (DOMI) <br />
                      ZENITH BANK PLC <br />
                      <strong>A/C:</strong> <br />
                      101-696-6094(₦) <br />
                      507-125-3571($USD)
                    </small>
                    <small className=' font-medium mt-3'>
                      Zoom link will be available to registered participants
                    </small>
                  </div>
                  <hr className=' my-3' />
                  <div className='flex flex-col mt-3'>
                    <span className='font-semibold'>Evening sessions </span>
                    <span>Free sessions</span>
                    <small>
                      Dominion City HQ <br />
                      Gudu, Abuja
                    </small>
                  </div>
                </div>
                <hr className=' my-3' />
                <small className='pt-3'>
                  Please send evidence of payment to: <br />
                  <strong>Pastor Best:</strong>{" "}
                  <a
                    href='tel:+2347060574969'
                    className='text-blue-600 underline'
                  >
                    0706-057-4969
                  </a>{" "}
                  <br />
                  <strong>Pastor Alex:</strong>{" "}
                  <a
                    href='tel:+2348065454601'
                    className='text-blue-600 underline'
                  >
                    0806-545-4601
                  </a>
                </small>
                <hr className=' my-3' />

                <div className='sm:mt-6 mt-6 flex sm:flex-row flex-col items-center justify-center'>
                  <Link
                    className='bg-white text-blue-900 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-blue-200 outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150'
                    // target='_blank'
                    // rel='noreferrer'
                    style={{ backgroundColor: "#b0940c", color: "#fff" }}
                    to='/register/abuja'
                  >
                    Register Online
                  </Link>
                </div>
              </div>
            </div>
          </div> */}

          <div className='w-full md:w-4/12 px-4 lg:pt-12 pt-6 text-center'>
            <div className='relative flex flex-col min-w-0 break-word bg-blue-900  w-full mb-8 shadow-lg rounded-lg h-full justify-center'>
              <div className='px-4 py-5'>
                <div className='text-blue-900  p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white'>
                  <i className='fas fa-map-marker-alt'></i>
                </div>
                <h6 className='text-xl font-semibold text-white uppercase'>
                  Lagos
                </h6>
                <p className='mt-2 mb-4 text-white'>
                  Golden Heart Place Km 22, <br />
                  Lekki-Ekpe Expressway, <br />
                  Besides LBS, Lagos.
                </p>
                <Link
                  className='bg-white text-gray-800 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-gray-400 outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150'
                  type='button'
                  to='/register/lagos'
                >
                  Register
                </Link>
              </div>
            </div>
          </div>

          {/* <div className='lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center'>
            <div className='relative flex flex-col min-w-0 break-words border border-blue-900 bg-white w-full mb-8 shadow-lg rounded-lg h-full justify-center'>
              <div className='px-4 py-5 '>
                <div className=' text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-900'>
                  <i className='fas fa-map-marker-alt'></i>
                </div>
                <h6 className='text-xl font-semibold text-blue-900 uppercase'>
                  Enugu
                </h6>
                <p className='mt-2 mb-4 text-blue-900'>
                  Golden Heart Conference Centre, <br />
                  Plot 18a Airport Road, Near Akanu <br />
                  Ibiam International Airport, Besides <br />
                  ANAMMCO, Emene, Enugu.
                </p>
                <Link
                  className='bg-blue-900 text-white active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-blue-800 outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150'
                  type='button'
                  to='/register/enugu'
                >
                  Register
                </Link>
              </div>
            </div>
          </div> */}
          {/* second section */}
          {/* <div className='lg:pt-12 lg:w-4/12 w-full px-4 text-center'>
            <div className='relative h-auto flex flex-col min-w-0 break-word bg-blue-900  w-full mb-8 shadow-lg '>
              <div className='px-4 py-5 flex-auto'>
                <div className='text-blue-900 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white'>
                  <i className='fas fa-map-marker-alt'></i>
                </div>
                <h6 className='text-xl font-semibold text-white uppercase'>
                  Enugu
                </h6>
                <p className='mt-2 mb-4 text-white'>
                  Golden Heart Conference Centre, <br />
                  Plot 18a Airport Road, <br />
                  ANAMMCO, Emene, Enugu.
                </p>

                <div className='sm:mt-14 mt-6 flex sm:flex-row flex-col items-center justify-center'>
                  <Link
                    className='bg-white text-blue-900 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-blue-200 outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150'
                    to='/register/enugu'
                    style={{ backgroundColor: "#b0940c", color: "#fff" }}
                  >
                    Register
                  </Link>
                  <Link
                    className='bg-white text-blue-800 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-blue-200 outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150'
                    to={{
                      pathname: "/accomodation",
                      state: { loaction: "ENUGU" },
                    }}
                  >
                    Accomodation Details
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Register;
