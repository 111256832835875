import React, { useEffect } from "react";
import logo from "../assets/DC-Logo's/dc-logo-1024.png";
import Footer from "../components/Footers/Footer";
import Missions from "../components/missions/Missions";
import Navbar from "../components/Navbars/AuthNavbar";

interface MissionsPageProps {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const MissionsPage: React.FC<MissionsPageProps> = ({
  setCurrentLocation,
  pathname,
}) => {
  useEffect(() => {
    setCurrentLocation("/missions");
  }, []);

  return (
    <React.Fragment>
      <Navbar pathname={pathname} />
      <div className="sm:pt-10 mt-9 mb-16">
        <div className="flex flex-wrap justify-center text-center sm:mb-10">
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex justify-center">
              <img
                src={logo}
                alt="Logo"
                className="img-fluid max-w-full"
                style={{
                  height: "10rem",
                  width: "10rem",
                }}
              />
            </div>
            <h2 className="text-3xl font-semibold">MISSION VOLUNTEERS FORM</h2>
          </div>
        </div>
        <Missions />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default MissionsPage;
