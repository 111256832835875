import React, { useEffect } from "react";
import { LoadPanel } from "devextreme-react";
import axios from "axios";
import { baseUrl } from "../utils/index";
import { useParams, useHistory } from "react-router-dom";

const PaymentVerification: React.FC = () => {
  const { reference }: { reference: string } = useParams();
  const { push } = useHistory();

  const verifyPayment = () => {
    axios
      .post(`${baseUrl}/give/verify`, { reference })
      .then(({ data }) => {
        if (data && data.data.status === "success") {
          push(`/confirmation?message="verified"`);
        } else {
          push(`/confirmation?message="not-verified"`);
        }
      })
      .catch((error) => {
        push(`/confirmation?message="not-verified"`);
      });
  };

  useEffect(() => {
    verifyPayment();
  }, [reference]);

  return (
    <div>
      <LoadPanel visible showIndicator showPane />
    </div>
  );
};

export default PaymentVerification;
