import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/DC-Logo's/dc-logo-1024.png";
import Footer from "../components/Footers/Footer";
import Navbar from "../components/Navbars/AuthNavbar";

interface PrivacyPageProps {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const PrivacyPage: React.FC<PrivacyPageProps> = ({
  setCurrentLocation,
  pathname,
}) => {
  useEffect(() => {
    setCurrentLocation("/contact");
  }, []);

  return (
    <React.Fragment>
      <Navbar pathname={pathname} />
      <div className="sm:pt-10 mt-9 mb-16">
        <div className="flex flex-wrap justify-center text-center sm:mb-10">
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex justify-center">
              <img
                src={logo}
                alt="Logo"
                className="img-fluid max-w-full"
                style={{
                  height: "10rem",
                  width: "10rem",
                }}
              />
            </div>
            <h2 className="text-3xl font-semibold">Privacy Policy</h2>
            <span className="text-sm text-gray-500">
              Last updated June 13, 2020
            </span>
          </div>
        </div>
        <div className="w-full md:w-3/4 ml-auto mr-auto px-4 mb-5">
          <p className="mt-3">
            Thank you for choosing to be part of our community at Dominion City.
            We are committed to protecting your personal information and your
            right to privacy. If you have any questions or concerns about our
            notice, or our practices with regards to your personal information,
            please contact us at <strong>info@dominioncity.cc</strong>. When you
            visit our mobile application, and use our services, you trust us
            with your personal information. We take your privacy very seriously.
            In this privacy notice, we seek to explain to you in the clearest
            way possible what information we collect, how we use it and what
            rights you have in relation to it. We hope you take some time to
            read through it carefully, as it is important. If there are any
            terms in this privacy notice that you do not agree with, please
            discontinue use of our Apps and our services. This privacy notice
            applies to all information collected through our mobile application,
            ("Apps"), and/or any related services, sales, marketing or events
            (we refer to them collectively in this privacy notice as the
            "Services"). Please read this privacy notice carefully as it will
            help you make informed decisions about sharing your personal
            information with us.
          </p>
          <div className="mb-3">
            <h2 className="text-capitalize">table of contents </h2>
            <p style={{ textTransform: "capitalize" }} className="content">
              1. What information do we collect? <br></br> 2. Will your
              information be shared with anyone? <br></br> 3. Do we use cookies
              and other tracking tecnologies? <br></br> 4. How long do we keep
              your information? <br></br> 5. How do we keep your information
              safe? <br></br> 6. Do we collect information from minors?{" "}
              <br></br>7. What are your policy rights? <br></br>8. Controls for
              do-not-track features <br></br> 9. Do California residents have
              specific privacy rights? <br></br> 10. Do we make updates to this
              policy? <br></br> 11. How can you contact us about this policy?
            </p>
          </div>
          <div>
            <h3>1. What information do we collect?</h3>
            <p className="mb-3">
              <strong>In Short: </strong> We may collect information regarding
              your
              <strong> push notifications</strong>, when you use our apps.{" "}
              <br></br> If you use our Apps, we may also collect the following
              information: <br></br>
              Push Notifications. We may request to send you push notifications
              regarding your account or the mobile application. If you wish to
              opt-out from receiving these types of communications, you may turn
              them off in your device's settings.
            </p>
            <h3>2. Will your information be shared with anyone?</h3>
            <p className="mb-3">
              <strong>In Short:</strong> We only share information with your
              consent, to comply with laws, to provide you with services, to
              protect your rights, or to fulfill business obligations. <br /> We
              may process or share data based on the following legal basis:{" "}
              <br /> <strong>Consent:</strong> We may process your data if you
              have given us specific consent to use your personal information in
              a specific purpose. <br />
              <strong>Legitimate Interests:</strong> We may process your data
              when it is reasonably necessary to achieve our legitimate business
              interests. <br /> <strong>Performance of a Contract:</strong>{" "}
              Where we have entered into a contract with you, we may process
              your personal information to fulfill the terms of our contract.{" "}
              <br /> <strong>Legal Obligations:</strong> We may disclose your
              information where we are legally required to do so in order to
              comply with applicable law, governmental requests, a judicial
              proceeding, court order, or legal process, such as in response to
              a court order or a subpoena (including in response to public
              authorities to meet national security or law enforcement
              requirements). <br />
              <strong>Vital Interests:</strong> We may disclose your information
              where we believe it is necessary to investigate, prevent, or take
              action regarding potential violations of our policies, suspected
              fraud, situations involving potential threats to the safety of any
              person and illegal activities, or as evidence in litigation in
              which we are involved. More specifically, we may need to process
              your data or share your personal information in the following
              situations: <br />
              <strong>
                {" "}
                Vendors, Consultants and Other Third-Party Service Providers.
              </strong>
              We may share your data with third party vendors, service
              providers, contractors or agents who perform services for us or on
              our behalf and require access to such information to do that work.
              Examples include: payment processing, data analysis, email
              delivery, hosting services, customer service and marketing
              efforts. We may allow selected third parties to use tracking
              technology on the Apps, which will enable them to collect data
              about how you interact with the Apps over time. This information
              may be used to, among other things, analyze and track data,
              determine the popularity of certain content and better understand
              online activity. Unless described in this Policy, we do not share,
              sell, rent or trade any of your information with third parties for
              their promotional purposes. <br />{" "}
              <strong>Business Transfers.</strong> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company. <br />{" "}
              <strong>Third-Party Advertisers.</strong> We may use third-party
              advertising companies to serve ads when you visit the Apps. These
              companies may use information about your visits to our Website(s)
              and other websites that are contained in web cookies and other
              tracking technologies in order to provide advertisements about
              goods and services of interest to you.
            </p>
            <h3>3. Do we use cookies and other tracking tecnologies?</h3>
            <p className="mb-3">
              <strong>In Short:</strong> We may use cookies and other tracking
              technologies to collect and store your information. <br /> We may
              use cookies and similar tracking technologies (like web beacons
              and pixels) to access or store information.
            </p>
            <h3>4. How long do we keep your information?</h3>
            <p className="mb-3">
              <strong>In Short:</strong> We keep your information for as long as
              necessary to fulfill the purposes outlined in this privacy notice
              unless otherwise required by law. <br /> We will only keep your
              personal information for as long as it is necessary for the
              purposes set out in this privacy notice, unless a longer retention
              period is required or permitted by law (such as tax, accounting or
              other legal requirements). <br /> No purpose in this policy will
              require us keeping your personal information for longer than 90
              days. When we have no ongoing legitimate business need to process
              your personal information, we will either delete or anonymize it,
              or, if this is not possible (for example, because your personal
              information has been stored in backup archives), then we will
              securely store your personal information and isolate it from any
              further processing until deletion is possible.
            </p>
            <h3>5. How do we keep your information safe?</h3>
            <p className="mb-3">
              <strong>In Short:</strong> We aim to protect your personal
              information through a system of organizational and technical
              security measures. <br /> We have implemented appropriate
              technical and organizational security measures designed to protect
              the security of any personal information we process. However,
              please also remember that we cannot guarantee that the internet
              itself is 100% secure. Although we will do our best to protect
              your personal information, transmission of personal information to
              and from our Apps is at your own risk. You should only access the
              services within a secure environment.
            </p>
            <h3>6. Do we collect information from minors?</h3>
            <p className="mb-3">
              <strong>In Short:</strong> We do not knowingly collect data from
              or market to children under 18 years of age. We do not knowingly
              solicit data from or market to children under 18 years of age.{" "}
              <br /> By using the Apps, you represent that you are at least 18
              or that you are the parent or guardian of such a minor and consent
              to such minor dependent’s use of the Apps. If we learn that
              personal information from users less than 18 years of age has been
              collected, we will deactivate the account and take reasonable
              measures to promptly delete such data from our records. If you
              become aware of any data we have collected from children under age
              18, please contact us at <strong>info@dominioncity.cc</strong> .
            </p>
            <h3>7. What are your privacy rights?</h3>
            <p className="mb-3">
              <strong>In Short:</strong> In some regions, such as the European
              Economic Area, you have rights that allow you greater access to
              and control over your personal information. You may review,
              change, or terminate your account at any time. <br /> In some
              regions (like the European Economic Area), you have certain rights
              under applicable data protection laws. These may include the right
              (i) to request access and obtain a copy of your personal
              information, (ii) to request rectification or erasure; (iii) to
              restrict the processing of your personal information; and (iv) if
              applicable, to data portability. In certain circumstances, you may
              also have the right to object to the processing of your personal
              information. To make such a request, please use the{" "}
              <strong>contact details</strong> provided below. We will consider
              and act upon any request in accordance with applicable data
              protection laws. If we are relying on your consent to process your
              personal information, you have the right to withdraw your consent
              at any time. Please note however that this will not affect the
              lawfulness of the processing before its withdrawal. If you are
              resident in the European Economic Area and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details:{" "}
              <a
                href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm."
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600"
              >
                here
              </a>{" "}
              <br />
              <strong>Cookies and similar technologies:</strong> Most Web
              browsers are set to accept cookies by default. If you prefer, you
              can usually choose to set your browser to remove cookies and to
              reject cookies. If you choose to remove cookies or reject cookies,
              this could affect certain features or services of our Apps. To
              opt-out of interest-based advertising by advertisers on our Apps
              visit{" "}
              <a
                href="http://www.aboutads.info/choices/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600"
              >
                http://www.aboutads.info/choices/.
              </a>
            </p>
            <h3>8. Controls for do-not-track features</h3>
            <p className="mb-3">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (“DNT”) feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. No
              uniform technology standard for recognizing and implementing DNT
              signals has been finalized. As such, we do not currently respond
              to DNT browser signals or any other mechanism that automatically
              communicates your choice not to be tracked online. If a standard
              for online tracking is adopted that we must follow in the future,
              we will inform you about that practice in a revised version of
              this privacy notice.
            </p>
            <h3>9. Do California residents have specific privacy rights?</h3>
            <p className="mb-3">
              <strong>In Short:</strong> Yes, if you are a resident of
              California, you are granted specific rights regarding access to
              your personal information. <br /> California Civil Code Section
              1798.83, also known as the “Shine The Light” law, permits our
              users who are California residents to request and obtain from us,
              once a year and free of charge, information about categories of
              personal information (if any) we disclosed to third parties for
              direct marketing purposes and the names and addresses of all third
              parties with which we shared personal information in the
              immediately preceding calendar year. If you are a California
              resident and would like to make such a request, please submit your
              request in writing to us using the contact information provided
              below. <br /> If you are under 18 years of age, reside in
              California, and have a registered account with the Apps, you have
              the right to request removal of unwanted data that you publicly
              post on the Apps. To request removal of such data, please contact
              us using the contact information provided below, and include the
              email address associated with your account and a statement that
              you reside in California. We will make sure the data is not
              publicly displayed on the Apps, but please be aware that the data
              may not be completely or comprehensively removed from our systems.
            </p>
            <h3>10. Do we make updates to this policy?</h3>
            <p className="mb-3">
              <strong>In Short: </strong> Yes, we will update this policy as
              necessary to stay compliant with relevant laws. <br /> We may
              update this privacy notice from time to time. The updated version
              will be indicated by an updated “Revised” date and the updated
              version will be effective as soon as it is accessible. If we make
              material changes to this privacy notice, we may notify you either
              by prominently posting a notice of such changes or by directly
              sending you a notification. We encourage you to review this
              privacy notice frequently to be informed of how we are protecting
              your information.
            </p>
            <h3>11. How can you contact us about this policy?</h3>
            <p className="mb-3">
              If you have questions or comments about this policy, you may email
              us at <strong>info@dominioncity.cc</strong> or by post to: <br />
              Dominion City <br />
              Golden Heart Place, km 22, Lekki-Epe Expressway, <br /> Lekki,
              Lagos <br /> Nigeria
            </p>
            <h3>
              How can you review, update, or delete the data we collect from
              you?
            </h3>
            <p>
              Based on the laws of some countries, you may have the right to
              request access to the personal information we collect from you,
              change that information, or delete it in some circumstances. To
              request to review, update, or delete your personal information,
              please visit:{" "}
              <Link to="/contact" className="text-blue-600">
                Contact Page
              </Link>{" "}
              We will respond to your request within 30 days.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default PrivacyPage;
