import React from "react";
import useFirestore from "../../hooks/useFirestore";
import { PrayerRequstI } from "../../types";
import { LoadPanel } from "devextreme-react";

const PrayerRequests: React.FC = () => {
  const { docs, isLoading } = useFirestore("prayerRequest");

  const spinner = () => {
    return (
      <div className="mb-40 h-screen">
        <LoadPanel
          visible={true}
          showIndicator={true}
          closeOnOutsideClick={false}
        />
      </div>
    );
  };
  return (
    <React.Fragment>
      {isLoading && spinner()}
      {docs.map((doc: PrayerRequstI) => {
        const {
          id,
          fullName,
          country,
          request,
          createdAt,
          contact,
          email,
        } = doc;

        return (
          <div
            className="container px-4 mx-auto mb-9 rounded-lg shadow-lg bg-gray-300 py-4"
            key={id}
          >
            <div className="flex flex-col w-full">
              <div className="mb-5">
                <div className="flex items-center justify-between">
                  <div>
                    <span className="font-bold text-gray-600">Name: </span>{" "}
                    {fullName}
                  </div>
                  <span className="text-sm text-gray-600">
                    {createdAt.toDate().toDateString()}
                  </span>
                </div>

                <div>
                  <span className="font-bold text-gray-600">
                    Contact(Mobile):{" "}
                  </span>{" "}
                  {contact}
                </div>

                <div>
                  <span className="font-bold text-gray-600">Email: </span>{" "}
                  {email}
                </div>

                <div>
                  <span className="font-bold text-gray-600">Country: </span>{" "}
                  {country}
                </div>
                <div>
                  <span className="font-bold text-gray-600">Request: </span>
                  {request}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default PrayerRequests;
