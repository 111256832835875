import React, { useState } from "react";
import { notify } from "../../utils/notification";
import { iNotification } from "react-notifications-component";

interface AdminLoginProps {
  setAuthState: (
    state: boolean,
    location: "lagos" | "enugu" | "admin" | "testify" | "prayer"
  ) => void;
}

const authState = {
  admin: { username: "admin", password: "superadmin" },
  prayer: { username: "prayer", password: "prayeradmin" },
  testify: { username: "testify", password: "testifyadmin" },
};

const AdminLogin: React.FC<AdminLoginProps> = ({ setAuthState }) => {
  const [formData, setData] = useState({ username: "", password: "" });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setData({ ...formData, [name]: value });
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { username, password } = formData;
    if (username && username === "admin") {
      const authentication = authState["admin"];
      if (
        authentication.username === username &&
        authentication.password === password
      ) {
        setAuthState(true, "admin");
      } else {
        setAuthState(false, "admin");
        notify({
          message: `Incorrect crendentials`,
          type: "danger",
        } as iNotification);
      }
    } else if (username && username === "prayer") {
      const authentication = authState["prayer"];
      if (
        authentication.username === username &&
        authentication.password === password
      ) {
        setAuthState(true, "prayer");
      } else {
        setAuthState(false, "prayer");
        notify({
          message: `Incorrect crendentials`,
          type: "danger",
        } as iNotification);
      }
    } else if (username && username === "testify") {
      const authentication = authState["testify"];
      if (
        authentication.username === username &&
        authentication.password === password
      ) {
        setAuthState(true, "testify");
      } else {
        setAuthState(false, "testify");
        notify({
          message: `Incorrect crendentials`,
          type: "danger",
        } as iNotification);
      }
    } else {
      notify({
        message: `Incorrect crendentials`,
        type: "danger",
      } as iNotification);
    }
  };

  return (
    <div className='container mx-auto px-4 h-full'>
      <div className='flex content-center items-center justify-center h-full'>
        <div className='w-full lg:w-4/12 px-4'>
          <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0'>
            <div className='rounded-t mb-0 px-6 py-6'>
              <div className='text-center mb-3'>
                <h6 className='text-gray-600 text-sm font-bold uppercase'>
                  Sign In to account
                </h6>
              </div>

              <hr className='mt-6 border-b-1 border-gray-400' />
            </div>
            <div className='flex-auto px-4 lg:px-10 py-10 pt-0'>
              <form onSubmit={handleLogin}>
                <div className='relative w-full mb-3'>
                  <label
                    className='block uppercase text-gray-700 text-xs font-bold mb-2'
                    htmlFor='grid-password'
                  >
                    Username
                  </label>
                  <input
                    type='text'
                    className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                    placeholder='Username'
                    required
                    name='username'
                    value={formData.username}
                    onChange={onChange}
                  />
                </div>

                <div className='relative w-full mb-3'>
                  <label
                    className='block uppercase text-gray-700 text-xs font-bold mb-2'
                    htmlFor='grid-password'
                  >
                    Password
                  </label>
                  <input
                    type='password'
                    className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                    placeholder='Password'
                    required
                    name='password'
                    value={formData.password}
                    onChange={onChange}
                  />
                </div>

                <div className='text-center mt-6'>
                  <button
                    className='bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150'
                    type='submit'
                  >
                    Sign In
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
