import React from "react";
import Navbar from "../components/Navbars/AuthNavbar";
import Footer from "../components/Footers/Footer";
import { useLocation } from "react-router-dom";
import SingleProject from "../components/BuildingProject/SingleProject";

const SingleProjectPage: React.FC = () => {
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      <Navbar pathname={pathname} />
      <SingleProject />
      <Footer />
    </React.Fragment>
  );
};

export default SingleProjectPage;
