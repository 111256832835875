export const baseUrl = `https://dc-verification-api.herokuapp.com`;

export const getTotalAmountWithPer = (
  amount: number,
  percentage: number
): number => {
  const per = (percentage / 100) * amount;

  return per + amount;
};

export type registrationClass =
  | "Working class"
  | "Uni student"
  | "Teen"
  | "Child"
  | "General"
  | "";

export const getFileType = (file: File) => {
  if (file.type.match("image.*")) return "image";

  if (file.type.match("video.*")) return "video";

  if (file.type.match("audio.*")) return "audio";

  if (file.type === "application/pdf") return "pdf";

  return "other";
};

export interface CurrencyData {
  name: string;
  symbol_native: string;
  symbol: string;
  code: string;
  name_plural: string;
  rounding: number;
  decimal_digits: number;
}

export interface CurrencyObject {
  [key: string]: CurrencyData;
}

interface FlattenedCurrencyData {
  name: string;
  symbol_native: string;
  symbol: string;
  code: string;
  name_plural: string;
  rounding: number;
  decimal_digits: number;
}

export function flattenCurrencyObject(
  obj: CurrencyObject
): FlattenedCurrencyData[] {
  const result: FlattenedCurrencyData[] = [];
  for (const currencyCode in obj) {
    if (obj.hasOwnProperty(currencyCode)) {
      const currencyData = obj[currencyCode];
      result.push({
        code: currencyCode,
        name: `${currencyData.name} - ${currencyCode} (${currencyData.symbol})`,
        symbol: currencyData.symbol,
        symbol_native: currencyData.symbol_native,
        name_plural: currencyData.name_plural,
        rounding: currencyData.rounding,
        decimal_digits: currencyData.decimal_digits,
      });
    }
  }
  return result;
}
