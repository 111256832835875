import React, { useEffect, useState } from "react";
import projectImage from "../../assets/1000.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { churchBuildingClient } from "../../lib/sanity";
import { Link } from "react-router-dom";

const buildingQuery = `
*[_type == "churchBuildingProject"] {
  _id,
  "coverImage": coverImage.asset->url,
  location,
  projectPhotos[]{
    "imageUrl": asset->url
  },
  projectVideos[] {
    "fileUrl": asset->url 
  }
}[0...50]
`;

const Card = (props: Record<string, any>) => {
  return (
    <div className='w-96 mx-auto rounded shadow-lg overflow-hidden'>
      <img
        className='w-full h-52'
        src={props.coverImage}
        alt={props.location}
      />
      <div className='px-6 py-4 flex justify-between items-center'>
        <div className='font-bold uppercase'>{props.location}</div>
        <div>
          <Link
            to={`/church-building-projects/${props._id}`}
            className='bg-blue-900 text-white active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-blue-500 outline-none focus:outline-none mb-9 ease-linear transition-all duration-150 text-center'
          >
            View
          </Link>
        </div>
      </div>
    </div>
  );
};

const Projects: React.FC = () => {
  const [projects, setProjects] = useState<any[]>([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    // variableWidth: true,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: false,
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const data = await churchBuildingClient.fetch(buildingQuery);
        setProjects(data);
      } catch (error) {
        console.log({ error });
      }
    };

    fetchProjects();
  }, []);

  return (
    <section>
      <div className='w-full pt-16 pb-8'>
        <Slider {...settings}>
          <div style={{ width: "100%" }}>
            <img src={projectImage} alt='Project' className='w-full' />
          </div>
        </Slider>
      </div>
      <div className='container px-4 mx-auto mb-9'>
        <div className='w-full mb-10'>
          <h1 className=' text-3xl text-center mb-4'>Ongoing Projects</h1>
          <div className='flex flex-wrap gap-4'>
            {projects.map((pro) => (
              <Card {...pro} key={pro._id} />
            ))}
          </div>
        </div>

        <div className='flex gap-12 border-gray-200 border p-6 mb-36 justify-center sm:flex-row flex-col'>
          <div>
            <h4 className=' font-bold'>Contact Details</h4>
            <p>For more enquiries, pls call:</p>
            <a
              className='block text-blue-600 underline'
              href='tel:+2348035508230'
            >
              +2348035508230
            </a>
            <a className='text-blue-600 underline' href='tel:+2348034954566'>
              +2348034954566
            </a>
          </div>

          <div className='mb-12'>
            <h4 className='font-bold'>Account Numbers</h4>
            <p>
              1000 CHURCH PROJECT <br /> GTBANK <br /> Dominion City <br />{" "}
              <strong>NGN:</strong>
              0283265571 <br /> <strong>USD:</strong> 0283265588 <br />{" "}
              <strong>GBP:</strong> 0283265595 <br /> <strong>EUR:</strong>{" "}
              0283266389 <br /> <strong>SORT CODE:</strong>
              058152405 <br /> <strong>SWIFT CODE:</strong> GTBINGLA
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
