import React, { useState, useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import { baseUrl, getTotalAmountWithPer } from "../../utils";
import { usePaystackPayment } from "react-paystack";
import { v4 as uuidv4 } from "uuid";
import { notify } from "../../utils/notification";
import { iNotification } from "react-notifications-component";
import { db, timestamp } from "../../firebase/firebase.utils";
import axios from "axios";
import { MySwal } from "../../App";

interface PartnershipProps {}

interface PartnershipData {
  fullName: string;
  email: string;
  amount: string;
  remark: string;
  country: string;
  contact: string;
  channel: string;
  partnerType:
    | "Partnership with Dominion City"
    | "Partnership with Pastor David Ogbueli"
    | "Partnership with DOMI (David Ogbueli Ministries)"
    | "";
}

const initialState: PartnershipData = {
  fullName: "",
  email: "",
  amount: "",
  remark: "",
  country: "",
  contact: "",
  partnerType: "",
  channel: "",
};

const getSubKey = (type: string) => {
  switch (type) {
    case "Partnership with Dominion City":
      return process.env.REACT_APP_PARTNERSHIP_SUB_ACCOUNT;
    case "Partnership with DOMI (David Ogbueli Ministries)":
      return process.env.REACT_APP_DOMI;
    default:
      return process.env.REACT_APP_PARTNERSHIP_REV_DAVID_SUB_ACCOUNT;
  }
};

const Partnership: React.FC<PartnershipProps> = () => {
  const [country, setCountry] = useState<any>("");
  const [type, setType] = useState<any>("");
  const [partnerShipType, setPartnerType] = useState<any>("");
  const [formData, setData] = useState<PartnershipData>(initialState);
  const [channel, setChannel] = useState<any>("");

  const { fullName, email, amount, remark, contact, partnerType } = formData;

  const options = useMemo(() => countryList().getData(), []);

  const config: any = {
    reference: uuidv4(),
    email: email,
    amount: getTotalAmountWithPer(parseInt(amount, 10), 1.5) * 100,
    publicKey: process.env.REACT_APP_MAIN_PUBLIC_STACK_KEY,
    subaccount: getSubKey(partnerType),
  };

  const initializePayment = usePaystackPayment(config);

  const changeHandler = (
    value: any,
    type: "country" | "paymentType" | "partnerType" | "channel"
  ) => {
    if (type === "country") {
      setCountry(value);
      if (value) {
        setData({ ...formData, country: value.label });
      } else {
        setData({ ...formData, country: "" });
      }
    } else if (type === "paymentType") {
      setType(value);
      if (value) {
        setData({ ...formData, remark: value.value });
      } else {
        setData({ ...formData, remark: "" });
      }
    } else if (type === "channel") {
      setChannel(value);
      if (value) {
        setData({ ...formData, channel: value.value });
      } else {
        setData({ ...formData, channel: "" });
      }
    } else {
      setPartnerType(value);
      if (value) {
        setData({ ...formData, partnerType: value.value });
      } else {
        setData({ ...formData, partnerType: "" });
      }
    }
  };

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setData({ ...formData, [name]: value });
  };

  const sendMail = async (verified: boolean, formData: PartnershipData) => {
    try {
      const give_data = {
        fullName: formData.fullName,
        email: formData.email,
        type: formData.partnerType,
        remark: formData.remark,
        amount: parseInt(formData.amount, 10),
      };
      await axios.post(`${baseUrl}/give/data`, {
        give_data,
        sendTo: "give@dominioncity.cc",
        verified: verified ? "Yes" : "No",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const saveToDB = (verified: boolean, formData: PartnershipData) => {
    db.collection("accounts").add({
      fullName: formData.fullName,
      email: formData.email,
      type: formData.partnerType,
      remark: formData.remark,
      amount: parseInt(formData.amount, 10),
      verified: verified ? "Yes" : "No",
      createdAt: timestamp(),
      country: formData.country,
      contact: formData.contact,
      paymentChannel: formData.channel,
    });
  };

  const onClose = () => {
    notify({
      message: `Payment cancelled`,
      type: "info",
    } as iNotification);
  };

  const onSuccess = (data: any) => {
    const { reference } = data;
    let newObj = JSON.parse(JSON.stringify(formData)) as PartnershipData;
    if (reference) {
      axios
        .post(`${baseUrl}/give/verify`, { reference })
        .then(({ data }) => {
          if (data && data.data.status === "success") {
            sendMail(true, newObj);
            saveToDB(true, newObj);
            notify({
              message: `Payment successful, God bless you!!`,
              type: "success",
            } as iNotification);
            setData(initialState);
            setCountry("");
            setType("");
            setPartnerType("");
          } else {
            notify({
              message: `Payment sent, Please contact the admin to verify payment`,
              type: "info",
            } as iNotification);
            saveToDB(false, newObj);
            sendMail(false, newObj);
            setData(initialState);
            setCountry("");
            setType("");
            setPartnerType("");
          }
        })
        .catch((error) => {
          sendMail(false, newObj);
          saveToDB(false, newObj);
          notify({
            message: `Payment sent, Please contact the admin to verify payment`,
            type: "info",
          } as iNotification);
          setData(initialState);
          setCountry("");
          setType("");
          setPartnerType("");
        });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!remark || !partnerType || !country || !channel) {
      notify({
        message: `All form fields are required`,
        type: "danger",
      } as iNotification);
      return;
    }

    if (formData.channel === "Card") {
      initializePayment(onSuccess, onClose);
    } else if (formData.channel === "Zelle") {
      MySwal.fire({
        icon: "info",
        title: "Zelle to Dominion City Global",
        text: `Email: give@dominioncityglobal.org`,
        confirmButtonColor: "#0d47a1",
      });
    } else {
      window.location.href = "http://paypal.me/dominioncityglobal";
    }
  };

  return (
    <div className='w-full md:w-5/12 ml-auto mr-auto px-4 mb-5'>
      <form onSubmit={handleSubmit}>
        <div className=''>
          <div className='relative flex flex-col min-w-0 break-words w-full shadow-lg bg-blue-900'>
            <div className='flex-auto p-5 lg:p-10'>
              {/* <h4 className="text-2xl text-white font-semibold">
              Have a Message ?
            </h4> */}
              <small className='leading-relaxed mt-1 mb-4 text-gray-300'>
                Administrative charge applies
              </small>
              <div className='relative w-full mb-3 mt-5'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='name'
                >
                  Full Name
                </label>
                <input
                  type='text'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='Full Name'
                  name='fullName'
                  id='name'
                  value={fullName}
                  onChange={onChange}
                  required
                />
              </div>

              <div className='relative w-full mb-3'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='email'
                >
                  Email
                </label>
                <input
                  type='email'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='Email'
                  name='email'
                  id='email'
                  value={email}
                  onChange={onChange}
                  required
                />
                <small className='text-gray-300 text-xs'>
                  Please add a valid email. Payment receipt will be sent to it
                </small>
              </div>
              <div className='relative w-full mb-3 '>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='contact'
                >
                  Contact(Mobile)
                </label>
                <input
                  type='text'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='Contact number'
                  name='contact'
                  id='contact'
                  required
                  value={contact}
                  onChange={onChange}
                />
                <small className='text-gray-300 text-xs'>
                  Please add country code e.g (+234)
                </small>
              </div>
              <div className='relative w-full mb-3 mt-5'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='amount'
                >
                  Amount
                </label>
                <input
                  type='number'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='Amount'
                  name='amount'
                  id='amount'
                  value={amount}
                  onChange={onChange}
                  required
                />
              </div>

              <div className='relative w-full mb-3 mt-5'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='partnerType'
                >
                  Partnership Type
                </label>

                <Select
                  options={[
                    {
                      label: "Partnership with Dominion City",
                      value: "Partnership with Dominion City",
                    },
                    {
                      label: "Partnership with Pastor David Ogbueli",
                      value: "Partnership with Pastor David Ogbueli",
                    },
                    {
                      label: "Partnership with DOMI (David Ogbueli Ministries)",
                      value: "Partnership with DOMI (David Ogbueli Ministries)",
                    },
                  ]}
                  value={partnerShipType}
                  isClearable
                  isSearchable
                  placeholder='Select option'
                  onChange={(value) => changeHandler(value, "partnerType")}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      // primary25: "#9fa6b2",
                      primary: "#1a56db",
                    },
                  })}
                />
              </div>

              <div className='relative w-full mb-3 mt-5'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='country'
                >
                  Payment Option
                </label>

                <Select
                  options={[
                    { label: "One-off", value: "One-off" },
                    { label: "Monthly", value: "Monthly" },
                  ]}
                  value={type}
                  isClearable
                  isSearchable
                  placeholder='Select option'
                  onChange={(value) => changeHandler(value, "paymentType")}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      // primary25: "#9fa6b2",
                      primary: "#1a56db",
                    },
                  })}
                />
              </div>
              <div className='relative w-full mb-3 mt-5'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='country'
                >
                  Payment Channel
                </label>

                <Select
                  options={[
                    {
                      label: "Give with Card ",
                      value: "Card",
                    },
                    {
                      label: "Give with Paypal (USD,GBP, EUR, CAD)",
                      value: "Other",
                    },
                    {
                      label: "Give with Zelle (USD)",
                      value: "Zelle",
                    },
                  ]}
                  value={channel}
                  isClearable
                  isSearchable
                  placeholder='Select payment channel'
                  onChange={(value) => changeHandler(value, "channel")}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      // primary25: "#9fa6b2",
                      primary: "#1a56db",
                    },
                  })}
                />
              </div>

              <div className='relative w-full mb-3 mt-5'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='country'
                >
                  Country
                </label>

                <Select
                  options={options}
                  value={country}
                  isClearable
                  isSearchable
                  placeholder='Select country'
                  onChange={(value) => changeHandler(value, "country")}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      // primary25: "#9fa6b2",
                      primary: "#1a56db",
                    },
                  })}
                />
              </div>

              <div className='text-center mt-6'>
                <button
                  className='bg-white text-blue-900 active:bg-gray-300 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none hover:bg-gray-300 focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                  type='submit'
                  style={{ backgroundColor: "#b0940c", color: "#fff" }}
                >
                  <i className='fas fa-money-bill mr-2'></i> Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Partnership;
