import { Link } from "react-router-dom";
import zelle from "../../assets/zelle.jpeg";

const Giving = () => {
  // sm:mt-32 mt-8
  return (
    <section className='pb-20 relative block bg-blue-900'>
      {/* <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
        style={{ transform: "translateZ(0)" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-blue-900 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div> */}
      <div className='container mx-auto px-4 lg:pt-24 lg:pb-64'>
        <div className='flex flex-wrap text-center justify-center mt-5'>
          <div className='w-full lg:w-6/12 px-4'>
            <h2 className='text-4xl font-semibold text-white sm:mt-0 mt-6'>
              Giving
            </h2>
            <p className='text-2xl leading-relaxed mt-4  text-gray-200'>
              Honour Pastor David, pay for partnership and projects
            </p>
          </div>
        </div>
        <div className='flex flex-wrap mt-12 justify-center'>
          <div className='w-full lg:w-3/12 px-4 flex items-center justify-center flex-col'>
            <div className='text-blue-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
              <i className='text-xl fas fa-gifts'></i>
            </div>
            <h6 className='text-xl mt-2 font-semibold text-white'>
              Honour Pastor David
            </h6>

            <Link
              to='/give/honour'
              className='bg-white text-blue-900 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-blue-200 outline-none focus:outline-none mb-9 ease-linear transition-all duration-150 text-center mt-3'
              style={{ backgroundColor: "#b0940c", color: "#fff" }}
            >
              Give
            </Link>
          </div>
          <div className='w-full lg:w-3/12 px-4 flex items-center justify-center flex-col'>
            <div className='text-blue-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
              <i className=' text-xl far fa-handshake'></i>
            </div>
            <h5 className='text-xl mt-2 font-semibold text-white'>
              Partnership
            </h5>

            <Link
              to='/give/partnership'
              className='bg-white text-blue-900 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-blue-200 outline-none focus:outline-none mb-9 ease-linear transition-all duration-150 text-center mt-3'
              style={{ backgroundColor: "#b0940c", color: "#fff" }}
            >
              Give
            </Link>
          </div>
          <div className='w-full lg:w-3/12 px-4 flex items-center justify-center flex-col'>
            <div className='text-blue-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
              <i className=' text-xl fas fa-tasks'></i>
            </div>
            <h5 className='text-xl mt-2 font-semibold text-white'>Projects</h5>

            <Link
              to='/give/projects'
              className='bg-white text-blue-900 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-blue-200 outline-none focus:outline-none  mb-9 ease-linear transition-all duration-150 text-center mt-3'
              style={{ backgroundColor: "#b0940c", color: "#fff" }}
            >
              Give
            </Link>
          </div>
        </div>
        <div className='flex flex-wrap text-center justify-center'>
          <div className='w-full lg:w-6/12 px-4'>
            <p className='text-2xl leading-relaxed mt-4 mb-4 text-gray-200'>
              Pay your{" "}
              <span className='font-bold' style={{ color: "#b0940c" }}>
                firstfruit
              </span>
              , offerings, tithe, vows & donations using any of the medium below
            </p>
          </div>
        </div>
        <div className='flex flex-wrap mt-5 justify-center'>
          <div className='w-full lg:w-3/12 px-4 text-center mt-9'>
            <div className='text-blue-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
              <i className='text-xl fab fa-paypal'></i>
            </div>
            <h6 className='text-xl mt-2 font-semibold text-white'>Paypal</h6>
            <p className='mt-2 mb-4 text-gray-200'>
              Give with Paypal | SECURED | USD, GBP, EUR, CAD
            </p>

            <a
              href='http://paypal.me/dominioncityglobal'
              className='bg-white text-blue-900 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-blue-200 outline-none focus:outline-none mb-9 ease-linear transition-all duration-150'
              target='_blank'
              rel='noreferrer'
              style={{ backgroundColor: "#b0940c", color: "#fff" }}
            >
              Give
            </a>
          </div>
          <div className='w-full lg:w-3/12 px-4 text-center mt-9'>
            <div className='text-blue-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
              <i className=' text-xl fas fa-align-left'></i>
            </div>
            <h5 className='text-xl mt-2 font-semibold text-white'>
              Card & Transfer
            </h5>
            <p className='sm:mt-10 mt-2 mb-4 text-gray-200'>Give with Card</p>

            <Link
              to='/give/offerings'
              // href="https://paystack.com/pay/dominioncity"
              className='bg-white text-blue-900 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-blue-200 outline-none focus:outline-none mb-9 ease-linear transition-all duration-150'
              // target="_blank"
              // rel="noreferrer"
              style={{ backgroundColor: "#b0940c", color: "#fff" }}
            >
              Give
            </Link>
          </div>
          <div className='w-full lg:w-3/12 px-4 text-center pt-9'>
            <div className='text-blue-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
              <i className=' text-xl fas fa-money-check'></i>
            </div>
            <h5 className='text-xl mt-2 font-semibold text-white'>
              Bank Details
            </h5>
            <p className='mt-2 mb-4 text-gray-200'>
              Give via Bank Transfer | SECURED | USD, NGN
            </p>

            <Link
              to='/give/bank-transfer'
              className='bg-white text-blue-900 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-blue-200 outline-none focus:outline-none  mb-9 ease-linear transition-all duration-150'
              style={{ backgroundColor: "#b0940c", color: "#fff" }}
            >
              Give
            </Link>
          </div>
          <div className='w-full lg:w-3/12 px-4 text-center mt-9'>
            <div className='text-blue-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center'>
              <img src={zelle} alt='' />
            </div>
            <h6 className='text-xl mt-2 font-semibold text-white'>Zelle</h6>
            <p className='mt-2 mb-4 text-gray-200'>
              Zelle to Dominion City Global <br />
              Email:{" "}
              <a href='mailto:give@dominioncityglobal.org'>
                give@dominioncityglobal.org
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Giving;
