import { LoadPanel } from "devextreme-react";
import React, { useState, useEffect } from "react";
import useFirestore from "../../hooks/useFirestore";
import { TestimonyI } from "../../types";

interface TestimonyProps {
  setSelectedImg: (value: React.SetStateAction<string | null>) => void;
}

const Testimony: React.FC<TestimonyProps> = ({ setSelectedImg }) => {
  const [images, setImages] = useState<
    { url: string; fullName: string; id: string }[]
  >([]);
  const [videos, setVideos] = useState<
    { url: string; fullName: string; id: string }[]
  >([]);
  const [pdfs, setPdfs] = useState<
    { url: string; fullName: string; id: string }[]
  >([]);

  const { docs, isLoading } = useFirestore("testimony");

  useEffect(() => {
    if (docs.length) {
      const pdf: { url: string; fullName: string; id: string }[] = [];
      const imgs: { url: string; fullName: string; id: string }[] = [];
      const vid: { url: string; fullName: string; id: string }[] = [];
      docs.forEach((doc: TestimonyI) => {
        const { file, fullName, id } = doc;
        if (file && file.length) {
          file.forEach((fi) => {
            const { type, url } = fi;
            if (type === "image") {
              imgs.push({ url, fullName, id });
            }

            if (type === "video") {
              vid.push({ url, fullName, id });
            }

            if (type === "pdf") {
              pdf.push({ url, fullName, id });
            }
          });
        }
      });
      setImages(imgs);
      setPdfs(pdf);
      setVideos(vid);
    }
  }, [docs]);

  const spinner = () => {
    return (
      <div className="mb-40 h-screen">
        <LoadPanel
          visible={true}
          showIndicator={true}
          closeOnOutsideClick={false}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      {isLoading && spinner()}
      {docs.map((doc: TestimonyI) => {
        const {
          fullName,
          file,
          id,
          country,
          testimony,
          createdAt,
          contact,
          email,
        } = doc;
        return (
          <div
            className="container px-4 mx-auto mb-9 rounded-lg shadow-lg bg-gray-300 py-4"
            key={id}
            // style={{ backgroundColor: "#b0940c", color: "#fff" }}
          >
            <div className="flex flex-col w-full">
              <div className="mb-5">
                <div className="flex items-center justify-between">
                  <div>
                    <span className="font-bold text-gray-600">Name: </span>{" "}
                    {fullName}
                  </div>
                  <span className="text-sm text-gray-600">
                    {createdAt.toDate().toDateString()}
                  </span>
                </div>

                <div>
                  <span className="font-bold text-gray-600">
                    Contact(Mobile):{" "}
                  </span>{" "}
                  {contact}
                </div>
                <div>
                  <span className="font-bold text-gray-600">Email: </span>{" "}
                  {email}
                </div>
                <div>
                  <span className="font-bold text-gray-600">Country: </span>{" "}
                  {country}
                </div>
                <div>
                  <span className="font-bold text-gray-600">Testimony: </span>
                  {testimony}
                </div>
              </div>
              {file.length ? (
                <React.Fragment>
                  <h4 className="mb-3 font-semibold text-gray-600 text-xl">
                    {" "}
                    Testmony Image(s)
                  </h4>
                  <div className="flex flex-wrap w-full sm:flex-row flex-col mb-5">
                    {images.map((img, index: number) => {
                      const { url } = img;
                      if (img.id === id) {
                        return (
                          <img
                            key={`${index}-${id}-${img.fullName}`}
                            src={url}
                            alt=""
                            width={300}
                            height={300}
                            className="mr-3 mb-3 cursor-pointer"
                            onClick={() => setSelectedImg(url)}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                  <h4 className="mb-3 font-semibold text-gray-600 text-xl">
                    {" "}
                    Testmony Video(s)
                  </h4>
                  <div className="flex flex-wrap w-full sm:flex-row flex-col mb-5">
                    {videos.map((vid, index: number) => {
                      if (vid.id === id) {
                        return (
                          <div
                            className="mr-3 mb-3 cursor-pointer sm:w-1/3 w-full"
                            key={`${index}-${id}-${vid.fullName}`}
                          >
                            <video src={vid.url} controls></video>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                  <h4 className="mb-3 font-semibold text-gray-600 text-xl">
                    {" "}
                    Testmony Files(s)
                  </h4>
                  <div className="flex flex-wrap w-full sm:flex-row flex-col mb-5">
                    {pdfs.map((pdf, index) => {
                      if (pdf.id === id) {
                        return (
                          <div
                            className="mr-3 mb-3 cursor-pointer"
                            key={`${index}-${id}-${pdf.fullName}`}
                          >
                            <a
                              href={pdf.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 underline"
                            >
                              File
                            </a>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default Testimony;
