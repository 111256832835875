import React from "react";
import DataGrid, {
  Column,
  ColumnChooser,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
  FilterRow,
  Selection,
  Export,
} from "devextreme-react/data-grid";
import useFirestore from "../../hooks/useFirestore";
import { Spinner } from "../common/Spinner";

const BirthdayGrid = () => {
  const { docs, isLoading } = useFirestore("birthday");
  return (
    <div className='container mx-auto mb-12'>
      {isLoading ? (
        <Spinner />
      ) : (
        <DataGrid
          dataSource={docs}
          showBorders={true}
          showRowLines={true}
          showColumnLines={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          hoverStateEnabled={true}
          wordWrapEnabled={true}
          columnHidingEnabled={true}
        >
          <Selection
            mode='multiple'
            selectAllMode='page'
            showCheckBoxesMode='always'
          />

          <HeaderFilter allowSearch={true} visible={true} />

          <FilterRow visible={true} applyFilter='auto' />

          <SearchPanel
            visible={true}
            searchVisibleColumnsOnly={true}
            highlightSearchText={true}
          />
          <ColumnChooser enabled={true} mode='select' allowSearch={true} />

          <Export
            enabled={true}
            excelWrapTextEnabled={true}
            fileName='Data'
            allowExportSelectedData={true}
          />

          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 20]}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Column caption='Name' dataField='fullName' />
          <Column caption='Message' dataField='message' />
          <Column caption='Email' dataField='email' />
          <Column caption='Country' dataField='country' />
          <Column caption='Contact(Mobile)' dataField='contact' />
        </DataGrid>
      )}
    </div>
  );
};

export default BirthdayGrid;
