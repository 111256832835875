import React, { useState, useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import { notify } from "../../utils/notification";
import { iNotification } from "react-notifications-component";
import { db, timestamp } from "../../firebase/firebase.utils";

interface GeneralProps {}

interface GeneralFormData {
  fullName: string;
  email: string;
  amount: string;
  comment: string;
  contact: string;
  country: string;
  state: string;
  type: string;
}

const initialState: GeneralFormData = {
  fullName: "",
  email: "",
  amount: "",
  comment: "",
  contact: "",
  country: "",
  state: "",
  type: "",
};

const General: React.FC<GeneralProps> = ({}) => {
  const [formData, setData] = useState<GeneralFormData>(initialState);
  const [country, setCountry] = useState<any>("");
  const [formType, setType] = useState<any>("");
  const options = useMemo(() => countryList().getData(), []);

  const { fullName, email, amount, comment, contact, state, type } = formData;

  const changeHandler = (value: any, type: "country" | "formType") => {
    if (type === "country") {
      setCountry(value);
      if (value) {
        setData({ ...formData, country: value.label });
      } else {
        setData({ ...formData, country: "" });
      }
    } else {
      setType(value);
      if (value) {
        setData({ ...formData, type: value.value });
      } else {
        setData({ ...formData, type: "" });
      }
    }
  };

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setData({ ...formData, [name]: value });
  };

  const saveToDB = (formData: GeneralFormData) => {
    if (formData.type === "Partnership") {
      db.collection("partnershipform")
        .add({
          fullName: formData.fullName,
          email: formData.email,
          createdAt: timestamp(),
          country: formData.country,
          contact: formData.contact,
          comment: formData.comment,
          amount: parseInt(formData.amount, 10),
        })
        .then(() => {
          notify({
            message: `Contact received, God bless you!!`,
            type: "success",
          } as iNotification);
          setData(initialState);
          setCountry("");
          setType("");
        })
        .catch((err) => {
          notify({
            message: `Error saving contact. Please try again`,
            type: "danger",
          } as iNotification);
        });
    } else if (formData.type === "Mission Volunteer") {
      db.collection("missions")
        .add({
          fullName: formData.fullName,
          email: formData.email,
          createdAt: timestamp(),
          country: formData.country,
          state: formData.state,
          contact: formData.contact,
          comment: formData.comment,
        })
        .then(() => {
          notify({
            message: `Contact received, God bless you!!`,
            type: "success",
          } as iNotification);
          setData(initialState);
          setCountry("");
          setType("");
        })
        .catch((err) => {
          notify({
            message: `Error saving contact. Please try again`,
            type: "danger",
          } as iNotification);
        });
    } else if (formData.type === "Pledge & Vows") {
      db.collection("pledgeAndVowsForm")
        .add({
          fullName: formData.fullName,
          email: formData.email,
          createdAt: timestamp(),
          country: formData.country,
          contact: formData.contact,
          comment: formData.comment,
          amount: parseInt(formData.amount, 10),
        })
        .then(() => {
          notify({
            message: `Contact received, God bless you!!`,
            type: "success",
          } as iNotification);
          setData(initialState);
          setCountry("");
          setType("");
        })
        .catch((err) => {
          notify({
            message: `Error saving contact. Please try again`,
            type: "danger",
          } as iNotification);
        });
    } else {
      db.collection("generalFormSubmission")
        .add({
          fullName: formData.fullName,
          email: formData.email,
          createdAt: timestamp(),
          country: formData.country,
          contact: formData.contact,
          comment: formData.comment,
          type: formData.type,
        })
        .then(() => {
          notify({
            message: `Contact received, God bless you!!`,
            type: "success",
          } as iNotification);
          setData(initialState);
          setCountry("");
          setType("");
        })
        .catch((err) => {
          notify({
            message: `Error saving contact. Please try again`,
            type: "danger",
          } as iNotification);
        });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formData.country || !formData.type) {
      notify({
        message: `All form fields are required`,
        type: "danger",
      } as iNotification);
      return;
    }
    let newObj = JSON.parse(JSON.stringify(formData)) as GeneralFormData;
    saveToDB(newObj);
  };

  return (
    <div className='w-full md:w-5/12 ml-auto mr-auto px-4 mb-5'>
      <form onSubmit={handleSubmit}>
        <div className=' '>
          <div className='relative flex flex-col min-w-0 break-words w-full shadow-lg bg-blue-900'>
            <div className='flex-auto p-5 lg:p-10'>
              {/* <h4 className="text-2xl text-white font-semibold">
          Have a Message ?
        </h4> */}
              {/* <small className="leading-relaxed mt-1 mb-4 text-gray-300">
              Administrative charge applies
            </small> */}
              <div className='relative w-full mb-3 mt-5'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='name'
                >
                  Full Name
                </label>
                <input
                  type='text'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='Full Name'
                  name='fullName'
                  id='name'
                  value={fullName}
                  onChange={onChange}
                  required
                />
              </div>

              <div className='relative w-full mb-3'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='email'
                >
                  Email
                </label>
                <input
                  type='email'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='Email'
                  name='email'
                  id='email'
                  value={email}
                  onChange={onChange}
                  required
                />
                {/* <small className="text-gray-300 text-xs">
                Please add a valid email. Payment receipt will be sent to it
              </small> */}
              </div>
              <div className='relative w-full mb-3 mt-8'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='contact'
                >
                  Contact(Mobile)
                </label>
                <input
                  type='text'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='Contact number'
                  name='contact'
                  id='contact'
                  required
                  value={contact}
                  onChange={onChange}
                />
                <small className='text-gray-300 text-xs'>
                  Please add country code e.g (+234)
                </small>
              </div>
              <div className='relative w-full mb-3 mt-5'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='country'
                >
                  Form category
                </label>

                <Select
                  options={[
                    {
                      label: "Give your life to Christ",
                      value: "Give your life to Christ",
                    },
                    { label: "Pledge & Vows", value: "Pledge & Vows" },
                    { label: "Partnership", value: "Partnership" },
                    { label: "Volunteering", value: "Volunteering" },
                    { label: "Counselling", value: "Counselling" },
                    { label: "Feedback", value: "Feedback" },
                  ]}
                  value={formType}
                  isClearable
                  isSearchable
                  placeholder='Select category'
                  onChange={(value) => changeHandler(value, "formType")}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      // primary25: "#9fa6b2",
                      primary: "#1a56db",
                    },
                  })}
                />
              </div>
              {type === "Partnership" || type === "Pledge & Vows" ? (
                <div className='relative w-full mb-3 mt-5'>
                  <label
                    className='block uppercase text-gray-300 text-xs font-bold mb-2'
                    htmlFor='amount'
                  >
                    Amount
                  </label>
                  <input
                    type='number'
                    className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                    placeholder='Amount'
                    name='amount'
                    id='amount'
                    value={amount}
                    onChange={onChange}
                    required
                  />
                </div>
              ) : null}

              <div className='relative w-full mb-3 mt-5'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='country'
                >
                  Country
                </label>

                <Select
                  options={options}
                  value={country}
                  isClearable
                  isSearchable
                  placeholder='Select country'
                  onChange={(value) => changeHandler(value, "country")}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      // primary25: "#9fa6b2",
                      primary: "#1a56db",
                    },
                  })}
                />
              </div>
              {type === "Mission Volunteer" && (
                <div className='relative w-full mb-3 mt-5'>
                  <label
                    className='block uppercase text-gray-300 text-xs font-bold mb-2'
                    htmlFor='state'
                  >
                    State
                  </label>
                  <input
                    type='text'
                    className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                    placeholder='State'
                    name='state'
                    id='state'
                    value={state}
                    onChange={onChange}
                    required
                  />
                </div>
              )}
              <div className='relative w-full mb-3'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='message'
                >
                  Comment
                </label>
                <textarea
                  rows={4}
                  cols={80}
                  id='message'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full'
                  placeholder='Leave a comment...'
                  onChange={onChange}
                  value={comment}
                  name='comment'
                />
              </div>

              <div className='text-center mt-6'>
                <button
                  className='bg-white text-blue-900 active:bg-gray-300 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none hover:bg-gray-300 focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                  type='submit'
                  style={{ backgroundColor: "#b0940c", color: "#fff" }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default General;
