import React from "react";
import logo from "../../assets/DC-Logo's/dc-logo-1024.png";

const BankTransfer = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const isWebView = searchParams.get("webview");
  return (
    <React.Fragment>
      <div className='flex flex-wrap justify-center text-center sm:mb-10'>
        <div className='w-full lg:w-6/12 px-4'>
          {!isWebView ? (
            <div className='flex justify-center'>
              <img
                src={logo}
                alt='Logo'
                className='img-fluid max-w-full'
                style={{
                  height: "10rem",
                  width: "10rem",
                }}
              />
            </div>
          ) : null}
          <h2 className='text-3xl font-semibold'>Bank Transfers</h2>
        </div>
      </div>
      <div className='w-full md:w-1/2 ml-auto mr-auto px-4 mb-20'>
        <div className='text-white px-6 py-4 border-0 rounded relative mb-4 bg-blue-500'>
          <span className='inline-block mr-5 align-middle'>
            <i className='fas fa-info-circle'></i>
            <span className='ml-3'>
              Please kindly state purpose of payment in the remark session
              during bank transfers
            </span>
          </span>
        </div>
        <h4 className='font-bold uppercase text-blue-900'>
          To honour Dr David Ogbueli
        </h4>
        <p className='mb-7'>
          GTB <br /> Chuks & Sarah Ogbueli <br />
          <strong>A/C:</strong> 0118033621 <br /> <br />
          Zenith Bank <br /> Chuks & Sarah Ogbueli <br />
          <strong>A/C:</strong> 1000465378 <br /> <br />
          For more enquiries, pls call: <br />
          <a href='tel:+2348035508230' className='text-blue-600 underline'>
            +2348035508230
          </a>{" "}
          <br />{" "}
          <a href='tel:+2348034954566' className='text-blue-600 underline'>
            +2348034954566
          </a>
        </p>

        <h4 className='font-bold uppercase text-blue-900'>
          1000 Church Project
        </h4>
        <p className='mb-7'>
          GTBANK <br /> Dominion City <br />
          <strong>NGN:</strong> 0283265571 <br />
          <strong>USD:</strong> 0283265588 <br />
          <strong>GBP:</strong> 0283265595 <br />
          <strong>EUR:</strong> 0283266389 <br />
          <strong>SORT CODE:</strong> 058152405 <br />
          <strong>SWIFT CODE:</strong> GTBINGLA
        </p>

        <h4 className='font-bold uppercase text-blue-900'>
          Firstfruit Account
        </h4>
        <p className='mb-9'>
          Zenith Bank Plc (NGN) <br /> David Ogbueli Ministries <br />{" "}
          <strong>A/C:</strong> 1012549668
        </p>
        <h4 className='font-bold uppercase text-blue-900'>
          Tithe and Offering Accounts
        </h4>
        <p className='mb-9'>
          Access Bank (NGN) <br /> Dominion City Abuja <br />{" "}
          <strong>A/C:</strong> 0032644541 <br /> <br />
          Stanbic IBTC Bank (NGN) <br /> Dominion City Abuja <br />{" "}
          <strong>A/C:</strong> 9201925793 <br /> <br />{" "}
          <strong>International Audience</strong> <br /> WELLS FARGO BANK (USD){" "}
          <br /> Dominion City Global <br /> <strong>A/C: </strong>
          3240700074 <br /> Intl routing no: 121000248 <br /> Domestic routing
          no: 061000227
        </p>
        <h4 className='font-bold uppercase text-blue-900'>Project Accounts</h4>

        <p>
          <strong>For Abuja ICC Project</strong> <br /> Zenith Bank PLC <br />{" "}
          Dominion City Abuja Project <br /> <strong>A/C:</strong> 1016322423
        </p>
        <br />
        <p>
          <strong>For Other Projects</strong> <br />
          Fidelity Bank (NGN) <br /> Dominion City (Main Account) <br />{" "}
          <strong>A/C:</strong> 4110041341 <br /> <br />{" "}
          <strong>For Local & International Audience</strong> <br /> Guaranty
          Trust Bank PLC (USD) <br /> Dominion City <br />{" "}
          <strong> A/C:</strong> 028-326-5588 <br /> Swift: GTBINGLA <br /> Sort
          code:058152403
        </p>
        <br />
        <p>
          <strong>For Enquiries, pls call:</strong> <br />{" "}
          <a href='tel:+2349036370546' className='text-blue-600 underline'>
            +234-9036370546
          </a>{" "}
          <br />{" "}
          <a href='tel:+2348103526864' className='text-blue-600 underline'>
            +234-8103526864
          </a>
        </p>
      </div>
    </React.Fragment>
  );
};

export default BankTransfer;
