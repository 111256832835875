import React, { useEffect } from "react";
import Login from "../components/Auth/Login";
import Footer from "../components/Footers/Footer";
import Navbar from "../components/Navbars/AuthNavbar";
import ReportTable from "../components/Table/ReportTable";

interface EnuguAdminPageProps {
  setCurrentLocation: (location: string) => void;
  setAuthState: (state: boolean, location: "lagos" | "enugu" | "abuja") => void;
  isAuthenticated: boolean;
  logout: () => void;
  pathname: string;
}

const EnuguAdminPage: React.FC<EnuguAdminPageProps> = ({
  setCurrentLocation,
  isAuthenticated,
  setAuthState,
  logout,
  pathname,
}) => {
  useEffect(() => {
    setCurrentLocation("/admin/enugu");
  }, []);
  return (
    <React.Fragment>
      <Navbar pathname={pathname} />
      <div className='sm:pt-10 mt-14'>
        <div className='flex flex-wrap justify-center text-center sm:mb-10'>
          {isAuthenticated ? (
            <div className='container px-4 mx-auto mb-12'>
              <div className='flex justify-end pt-3'>
                <button
                  className='bg-red-600 text-white active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md hover:bg-red-700 outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150'
                  onClick={logout}
                >
                  Logout
                </button>
              </div>
              <ReportTable location='Enugu' />
            </div>
          ) : (
            <Login setAuthState={setAuthState} location='enugu' />
          )}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default EnuguAdminPage;
