import React, { useEffect } from "react";
import logo from "../assets/DC-Logo's/dc-logo-1024.png";
// import ReactHlsPlayer from "react-hls-player";
import Navbar from "../components/Navbars/AuthNavbar";
import Footer from "../components/Footers/Footer";

interface LiveStreamProps {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const LiveStream: React.FC<LiveStreamProps> = ({
  setCurrentLocation,
  pathname,
}) => {
  useEffect(() => {
    setCurrentLocation("/livestream");
  }, []);

  return (
    <React.Fragment>
      <Navbar pathname={pathname} />
      <div className='sm:pt-10 mt-12 mb-16 '>
        <div className='flex flex-wrap justify-center text-center sm:mb-5'>
          <div className='w-full lg:w-6/12 px-4'>
            {/* <div className='flex justify-center mt-3 mb-3'>
              <img
                src={logo}
                alt='Logo'
                className='img-fluid max-w-full'
                style={{
                  height: "10rem",
                  width: "10rem",
                }}
              />
            </div> */}
            <h2 className='text-3xl font-semibold text-red-700 capitalize'>
              Livestream
            </h2>
          </div>
        </div>
        <div className='w-full flex justify-center lg:px-24 sm:mb-24 relative h-screen'>
          <iframe
            id='r-embed-player-iframe'
            src={`https://player.restream.io/?token=a1646c2489a94f51b4cf43d6ddfa9de0&vwrs=1`}
            allow='autoplay'
            allowFullScreen
            className='h-screen w-full overflow-hidden'
            title='LiveStream'
          ></iframe>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

// frameborder='0'
// allowfullscreen
// webkitallowfullscreen
// mozallowfullscreen

export default LiveStream;
