import sanityClient from "@sanity/client";

export const churchBuildingClient = sanityClient({
  projectId: "5gyy3ew2",
  dataset: "production",
  useCdn: true,
});

export default sanityClient({
  projectId: "1yr8nuid",
  dataset: "production",
  useCdn: true,
});
