/*eslint-disable*/
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/DC-Logo's/white-see-thru-logo-mark.png";
import Logo2 from "../../assets/DC-Logo's/dc-logo-1024.png";

// components

export default function Navbar({ pathname }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <>
      <nav className='top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg'>
        <div className='container px-4 mx-auto flex flex-wrap items-center justify-between'>
          <div className='w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start'>
            <Link
              className='text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase'
              to='/'
            >
              <img
                // src={pathname !== "/" ? Logo2 : Logo}
                src={Logo2}
                alt='Dominion city'
                width={30}
                height={30}
              />
            </Link>
            <button
              className='cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none'
              type='button'
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {/* <i
                className={
                  pathname !== "/"
                    ? "text-blue-900 fas fa-bars"
                    : "text-white fas fa-bars"
                }
              ></i> */}
              <i className={"text-blue-900 fas fa-bars"}></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg" : " hidden")
            }
            id='example-navbar-warning'
          >
            <ul className='flex flex-col lg:flex-row list-none mr-auto'>
              <li className='flex items-center'>
                <Link
                  to='/about'
                  // className={
                  //   pathname !== "/" && pathname !== "/give"
                  //     ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  //     : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  // }
                  className={
                    pathname !== "/give"
                      ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  About Us
                </Link>
              </li>
              <li className='flex items-center'>
                <Link
                  to='/contact'
                  // className={
                  //   pathname !== "/" && pathname !== "/give"
                  //     ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  //     : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  // }
                  className={
                    pathname !== "/give"
                      ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  Contact Us
                </Link>
              </li>
              <li className='flex items-center'>
                <Link
                  to='/online-forms'
                  // className={
                  //   pathname !== "/" && pathname !== "/give"
                  //     ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  //     : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  // }
                  className={
                    pathname !== "/give"
                      ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  Online Forms
                </Link>
              </li>
            </ul>
            <ul className='flex flex-col lg:flex-row list-none lg:ml-auto'>
              {/* <li className='flex items-center'>
                <Link
                  to='/register'
                  className={
                    pathname !== "/give"
                      ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  Register
                </Link>
              </li> */}
              {/* <li className="flex items-center">
                <Link
                  to="/accomodation"
                  className={
                    pathname !== "/give"
                      ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  Accomodation
                </Link>
              </li> */}
              <li className='flex items-center'>
                <Link
                  to='/give'
                  // className={
                  //   pathname !== "/" && pathname !== "/give"
                  //     ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  //     : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  // }
                  className={
                    pathname !== "/give"
                      ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  Give
                </Link>
              </li>
              <li className='flex items-center'>
                <Link
                  to='/testimony'
                  // className={
                  //   pathname !== "/" && pathname !== "/give"
                  //     ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  //     : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  // }
                  className={
                    pathname !== "/give"
                      ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  Testimony
                </Link>
              </li>
              <li className='flex items-center'>
                <Link
                  to='/prayer'
                  // className={
                  //   pathname !== "/" && pathname !== "/give"
                  //     ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  //     : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  // }
                  className={
                    pathname !== "/give"
                      ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  Prayer Request
                </Link>
              </li>

              <li className='flex items-center'>
                <Link
                  to='/gmtv'
                  // className={
                  //   pathname !== "/" && pathname !== "/give"
                  //     ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  //     : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  // }
                  className={
                    pathname !== "/give"
                      ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  onClick={() => setNavbarOpen(!navbarOpen)}
                >
                  GMTV
                </Link>
              </li>
              <li className='flex items-center'>
                <a
                  // className={
                  //   pathname !== "/" && pathname !== "/give"
                  //     ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  //     : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  // }
                  className={
                    pathname !== "/give"
                      ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  href='https://www.dominionmandateradio.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  DM Radio
                </a>
              </li>
              <li className='flex items-center'>
                <a
                  to='/live'
                  className={
                    pathname !== "/give"
                      ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  href='https://www.audio.dominioncity.cc'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Audio On Demand
                </a>
              </li>
              <li className='flex items-center'>
                <a
                  to='/live'
                  className={
                    pathname !== "/give"
                      ? "lg:text-blue-900 lg:hover:text-blue-800 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                      : "lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  }
                  href='https://www.youtube.com/channel/UCLrBzDNqm1pCxFjFqy3yQPA/live?sub_confirmation=1'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Video On Demand
                </a>
              </li>

              <li className='flex items-center'>
                <Link
                  to='/livestream'
                  className=' text-red-700 hover:text-red-600 px-3 py-4 lg:py-2 flex items-center text-sm uppercase font-bold'
                >
                  Livestream
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
