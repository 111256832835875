/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import NotificationDropdown from "../Dropdowns/NotificationDropdown";
// import UserDropdown from "../Dropdowns/UserDropdown.js";

export default function Sidebar({ setNav }) {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  return (
    <>
      <nav className='md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-blue-900 flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6'>
        <div className='md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto'>
          {/* Toggler */}
          <button
            className='cursor-pointer text-white opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
            type='button'
            onClick={() => setCollapseShow("bg-blue-900 m-2 py-3 px-6")}
          >
            <i className='fas fa-bars'></i>
          </button>
          {/* Brand */}
          <Link
            className='md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0'
            to='/'
          >
            Domininion city
          </Link>
          {/* User */}
          {/* <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <NotificationDropdown />
            </li>
            <li className="inline-block relative">
              <UserDropdown />
            </li>
          </ul> */}
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className='md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300'>
              <div className='flex flex-wrap'>
                <div className='w-6/12'>
                  <Link
                    className='md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0'
                    to='/'
                  >
                    Domininion city
                  </Link>
                </div>
                <div className='w-6/12 flex justify-end'>
                  <button
                    type='button'
                    className='cursor-pointer text-gray-200 opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className='fas fa-times'></i>
                  </button>
                </div>
              </div>
            </div>

            {/* Divider */}
            <hr className='my-4 md:min-w-full sm:visible invisible' />
            {/* Heading */}
            <h6 className='md:min-w-full text-gray-200 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
              General
            </h6>
            {/* Navigation */}

            <ul className='md:flex-col md:min-w-full flex flex-col list-none'>
              <li
                className='items-center'
                onClick={() => setNav("Testimonies")}
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/testimony") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/testimony'
                >
                  <i
                    className={
                      "fas fa-bullhorn mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/testimony") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Testimonies
                </Link>
              </li>

              <li
                className='items-center'
                onClick={() => setNav("Prayer Requests")}
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/prayer-request") !==
                    -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/prayer-request'
                >
                  <i
                    className={
                      "fas fa-pray mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/prayer-request") !==
                      -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Prayer Requests
                </Link>
              </li>
              <li className='items-center' onClick={() => setNav("Email List")}>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/mail-list") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/mail-list'
                >
                  <i
                    className={
                      "far fa-envelope mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/mail-list") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Email List
                </Link>
              </li>
              <li
                className='items-center'
                onClick={() => setNav("Birthday Message")}
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/birthday") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/birthday'
                >
                  <i
                    className={
                      "fas fa-birthday-cake mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/birthday") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Birthday Message
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className='my-4 md:min-w-full' />
            {/* Heading */}
            <h6 className='md:min-w-full text-gray-200 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
              Payments
            </h6>
            {/* Navigation */}

            <ul className='md:flex-col md:min-w-full flex flex-col list-none md:mb-4'>
              <li
                className='items-center'
                onClick={() => setNav("Pastor David Honour")}
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/honour") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/honour'
                >
                  <i
                    className={
                      "fas fa-gifts mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/honour") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Pastor David Honour
                </Link>
              </li>

              <li
                className='items-center'
                onClick={() => setNav("Partnership")}
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/partnership") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/partnership'
                >
                  <i
                    className={
                      "far fa-handshake mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/partnership") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Partnership
                </Link>
              </li>
              <li className='items-center' onClick={() => setNav("Projects")}>
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/projects") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/projects'
                >
                  <i
                    className={
                      "fas fa-tasks mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/projects") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Projects
                </Link>
              </li>
              <li
                className='items-center'
                onClick={() => setNav("Tithe, Offerings & Vows")}
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/offerings") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/offerings'
                >
                  <i
                    className={
                      "fas fa-donate mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/offerings") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Tithe, Offerings & Vows
                </Link>
              </li>
            </ul>
            {/* Divider */}
            <hr className='my-4 md:min-w-full' />
            {/* Heading */}
            <h6 className='md:min-w-full text-gray-200 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
              Forms
            </h6>
            {/* Navigation */}

            <ul className='md:flex-col md:min-w-full flex flex-col list-none md:mb-4'>
              <li
                className='items-center'
                onClick={() =>
                  setNav("Holy Ghost Conference Registration Data")
                }
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/holy-ghost") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/holy-ghost'
                >
                  <i
                    className={
                      "fas fa-dove mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/holy-ghost") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Holy Ghost Conference
                </Link>
              </li>
              <li
                className='items-center'
                onClick={() =>
                  setNav("Church Growth Conference Registration Data")
                }
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/church-growth") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/church-growth'
                >
                  <i
                    className={
                      "fas fa-church mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/church-growth") !==
                      -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Church Growth Conference
                </Link>
              </li>
              <li
                className='items-center'
                onClick={() => setNav("Missions Data")}
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/missions") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/missions'
                >
                  <i
                    className={
                      "fas fa-church mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/missions") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Missions
                </Link>
              </li>

              <li
                className='items-center'
                onClick={() => setNav("Partnership Form Data")}
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf(
                      "/admin/forms/partnership"
                    ) !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/forms/partnership'
                >
                  <i
                    className={
                      "far fa-handshake mr-2 text-sm " +
                      (window.location.href.indexOf(
                        "/admin/forms/partnership"
                      ) !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Partnership
                </Link>
              </li>
              <li
                className='items-center'
                onClick={() => setNav("Pledge & Vows Data")}
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/pledge") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/pledge'
                >
                  <i
                    className={
                      "fas fa-money-check mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/pledge") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Pledge & Vows
                </Link>
              </li>
              <li
                className='items-center'
                onClick={() => setNav("New Convert Form")}
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/new-convert") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/new-convert'
                >
                  <i
                    className={
                      "fas fa-sticky-note mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/new-convert") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  New Convert
                </Link>
              </li>
              <li
                className='items-center'
                onClick={() => setNav("Volunteers Forms")}
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/volunteer") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/volunteer'
                >
                  <i
                    className={
                      "fas fa-sticky-note mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/volunteer") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Volunteering
                </Link>
              </li>
              <li
                className='items-center'
                onClick={() => setNav("Counselling Forms")}
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/counselling") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/counselling'
                >
                  <i
                    className={
                      "fas fa-sticky-note mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/counselling") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Counselling
                </Link>
              </li>
              <li
                className='items-center'
                onClick={() => setNav("Feedback Forms")}
              >
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/feedback") !== -1
                      ? "text-yellow-300 hover:text-yellow-400"
                      : "text-gray-300 hover:text-gray-200")
                  }
                  to='/admin/feedback'
                >
                  <i
                    className={
                      "fas fa-sticky-note mr-2 text-sm " +
                      (window.location.href.indexOf("/admin/feedback") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Feedback
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
