import React, { useState } from "react";
import { notify } from "../../utils/notification";
import { iNotification } from "react-notifications-component";

interface LoginProps {
  setAuthState: (state: boolean, location: "lagos" | "enugu" | "abuja") => void;
  location: "lagos" | "enugu" | "abuja";
}

const authData = {
  lagos: { email: "lagosevents@dominioncity.cc", password: "dclagosadmin" },
  enugu: { email: "enuguevents@dominioncity.cc", password: "dcenuguadmin" },
  abuja: { email: "postencounter@dominioncity.cc", password: "dcabujaadmin" },
};

export default function Login(props: LoginProps) {
  const [formData, setData] = useState({ email: "", password: "" });

  const { setAuthState, location } = props;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setData({ ...formData, [name]: value });
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = formData;
    if (location === "lagos") {
      const authentication = authData[location];
      if (
        authentication.email === email &&
        authentication.password === password
      ) {
        setAuthState(true, location);
      } else {
        setAuthState(false, location);
        notify({
          message: `Incorrect crendentials`,
          type: "danger",
        } as iNotification);
      }
    } else if (location === "enugu") {
      const authentication = authData[location];
      if (
        authentication.email === email &&
        authentication.password === password
      ) {
        setAuthState(true, location);
      } else {
        setAuthState(false, location);
        notify({
          message: `Incorrect crendentials`,
          type: "danger",
        } as iNotification);
      }
    } else if (location === "abuja") {
      const authentication = authData[location];
      if (
        authentication.email === email &&
        authentication.password === password
      ) {
        setAuthState(true, location);
      } else {
        setAuthState(false, location);
        notify({
          message: `Incorrect crendentials`,
          type: "danger",
        } as iNotification);
      }
    }
  };

  return (
    <>
      <div className='container mx-auto px-4 h-full'>
        <div className='flex content-center items-center justify-center h-full'>
          <div className='w-full lg:w-4/12 px-4'>
            <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0'>
              <div className='rounded-t mb-0 px-6 py-6'>
                <div className='text-center mb-3'>
                  <h6 className='text-gray-600 text-sm font-bold uppercase'>
                    Sign In to account
                  </h6>
                </div>

                <hr className='mt-6 border-b-1 border-gray-400' />
              </div>
              <div className='flex-auto px-4 lg:px-10 py-10 pt-0'>
                <form onSubmit={handleLogin}>
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase text-gray-700 text-xs font-bold mb-2'
                      htmlFor='grid-password'
                    >
                      Email
                    </label>
                    <input
                      type='email'
                      className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                      placeholder='Email'
                      required
                      name='email'
                      value={formData.email}
                      onChange={onChange}
                    />
                  </div>

                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase text-gray-700 text-xs font-bold mb-2'
                      htmlFor='grid-password'
                    >
                      Password
                    </label>
                    <input
                      type='password'
                      className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                      placeholder='Password'
                      required
                      name='password'
                      value={formData.password}
                      onChange={onChange}
                    />
                  </div>

                  <div className='text-center mt-6'>
                    <button
                      className='bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150'
                      type='submit'
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
