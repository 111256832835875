import React, { useState, useEffect } from "react";
import { notify } from "../../utils/notification";
import { iNotification } from "react-notifications-component";

const Contact = () => {
  const [status, setStatus] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    notification();
  }, [isSubmitted, status]);

  const notification = () => {
    if (isSubmitted && status === "SUCCESS") {
      notify({
        message: `Message sent successfully, we will respond to you`,
        type: "success",
      } as iNotification);
    } else if (isSubmitted && status === "ERROR") {
      notify({
        message: `Error sending message, Please fill all fields`,
        type: "danger",
      } as iNotification);
    }
  };

  const submitForm = (e: any) => {
    e.preventDefault();
    const form = e.target;

    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        setStatus("SUCCESS");
        setIsSubmitted(true);
      } else {
        setStatus("ERROR");
        setIsSubmitted(true);
      }
    };
    xhr.send(data);
  };

  return (
    <div className='w-full md:w-5/12 ml-auto mr-auto px-4 mb-5'>
      <form
        action='https://formspree.io/f/mvovenpy'
        method='POST'
        onSubmit={submitForm}
      >
        <div className=' '>
          <div className='relative flex flex-col min-w-0 break-words w-full shadow-lg bg-blue-900'>
            <div className='flex-auto p-5 lg:p-10'>
              <h4 className='text-2xl text-white font-semibold'>
                Have a Message ?
              </h4>
              <p className='leading-relaxed mt-1 mb-4 text-gray-300'>
                Reach us with your question.
              </p>
              <div className='relative w-full mb-3 mt-8'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='name'
                >
                  Full Name
                </label>
                <input
                  type='text'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='Full Name'
                  name='name'
                  id='name'
                  required
                />
              </div>

              <div className='relative w-full mb-3'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='email'
                >
                  Email
                </label>
                <input
                  type='email'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='Email'
                  name='email'
                  id='email'
                  required
                />
              </div>

              <div className='relative w-full mb-3 '>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='contact'
                >
                  Who do you want to Conatct ?
                </label>

                <select
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='Email'
                  name='contact'
                  id='contact'
                  required
                >
                  <option value=''></option>
                  <option value='Dominion City'>Dominion City</option>
                  <option value='Dr David Ogbueli'>Dr David Ogbueli</option>
                </select>
              </div>

              <div className='relative w-full mb-3'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='message'
                >
                  Message
                </label>
                <textarea
                  rows={4}
                  cols={80}
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full'
                  placeholder='Type a message...'
                  name='message'
                  id='message'
                  required
                />
              </div>
              <div className='text-center mt-6'>
                <button
                  className='bg-white text-blue-900 active:bg-gray-300 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none hover:bg-gray-300 focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                  type='submit'
                  style={{ backgroundColor: "#b0940c", color: "#fff" }}
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Contact;
