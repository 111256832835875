import React, { useEffect } from "react";
import Footer from "../components/Footers/Footer";
import Giving from "../components/Give/Giving";
import Navbar from "../components/Navbars/AuthNavbar";

interface Props {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const GivePage: React.FC<Props> = ({ setCurrentLocation, pathname }) => {
  useEffect(() => {
    setCurrentLocation("/give");
  }, []);

  return (
    <React.Fragment>
      <Navbar pathname={pathname} />
      <div className="bg-white sm:pt-0 pt-14">
        <Giving />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default GivePage;
