import React from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/DC-Logo's/dc-logo-1024.png";

const Accomodation: React.FC = () => {
  const { state }: { state: { loaction: string } } = useLocation();

  return (
    <React.Fragment>
      {state.loaction === "LAGOS" ? (
        <>
          <div className='flex flex-wrap justify-center text-center sm:mb-10'>
            <div className='w-full lg:w-6/12 px-4'>
              <div className='flex justify-center'>
                <img
                  src={logo}
                  alt='Logo'
                  className='img-fluid max-w-full'
                  style={{
                    height: "10rem",
                    width: "10rem",
                  }}
                />
              </div>
              <h2 className='text-3xl font-semibold'>Lagos Accomodation</h2>
            </div>
          </div>
          <div className='w-full md:w-1/2 ml-auto mr-auto px-4 mb-20'>
            <div className='text-white px-6 py-4 border-0 rounded relative mb-4 bg-blue-500'>
              <span className='inline-block mr-5 align-middle'>
                <i className='fas fa-info-circle'></i>
                <span className='ml-3'>
                  As the countdown to CAMP MEETING 2022 continues, please secure
                  your hotel accommodation.
                </span>
              </span>
            </div>
            <h4 className='font-bold uppercase text-blue-900'>
              MOUTH WATERING ACCOMODATION PACKAGE
            </h4>
            <p className='mb-9'>
              Brand New EMPRESSA HOTEL with excellent facilities. <br /> Beside
              Dominion City Lagos HQ. <br /> 2mins walk to the venue <br />{" "}
              <br />
              59 Rooms. Limited rooms left. <br />
              Premium Rooms: ₦25,000 <br />
              Executive Suite: ₦40, 000 <br />
              Contact Number: <br /> Pst Emeka 08036717532 <br /> Pst Keith
              08037991035
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              Grays inn Ogombo road (5mins to venue)
            </h4>
            <p className='mb-9'>
              08091110438 <br />
              25rooms <br />
              Rates ₦13,000, ₦16,000, ₦18,000, ₦22,000
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              De-Dreams Hotel Okoado (15mins to venue
            </h4>
            <p className='mb-9'>
              Executive room from ₦20,950 <br />
              Contact Number- 09096661987 Supervisor
            </p>

            <h4 className='font-bold uppercase text-blue-900'>
              Patron Hotel Ogidan (20mins to venue)
            </h4>
            <p className='mb-9'>
              40 Rooms Available <br />
              Standard ₦25,000 per night <br />
              Executive: ₦30,000 per night with complimentary breakfast and free
              wifi included. <br />
              Contact Number- 08035781976 Manager
            </p>

            <h4 className='font-bold uppercase text-blue-900'>
              Jonaith Hotel Okoado (10mins to venue)
            </h4>
            <p className='mb-9'>
              25 Rooms Available <br />
              Annex classic Rm ₦15,000 <br />
              Annex Executive ₦20,000 <br />
              Main/B Classic ₦17,000 <br />
              Emerald Room ₦22,000 <br />
              Emerald Executive ₦30,000 <br />
              Vintage ₦25,000 <br />
              Vintage Executive ₦30,000 <br />
              Pool View ₦30,000 <br />
              Executive Pool View ₦35,000 <br />
              Executive ₦35,000 <br />
              Dulux ₦38,000 <br />
              Kings Suite ₦45,000 <br />
              Royal Suite ₦60,000 <br />
              Contact Number 08021449090 Manager
            </p>

            <h4 className='font-bold uppercase text-blue-900'>
              Gran Melia Hotel Alasia (opposite venue)
            </h4>
            <p className='mb-9'>
              Single rooms Available <br />
              (23) Rooms - Standard – ₦18,000 per night <br />
              (8) Rooms - Double – ₦27,000 per night <br />
              Apartments Available 12 Rooms <br />
              (4) Junior Suite – ₦30,000 per night <br />
              (4) Executive Suite – ₦40,000 per night <br />
              (4) Diplomatic Suite – ₦50,000 per night <br />
              Contact Number- 08163002000 Manager
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              Leawood Hotel Okoado (10mins to venue)
            </h4>
            <p className='mb-9'>
              10 Rooms Available <br />
              (10) standard Rooms - ₦20,000 <br />
              Contact Number- 08026707063 Manager
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              Jericho Villa Hotel (7mins to venue)
            </h4>
            <p className='mb-9'>
              10 Rooms Available <br />
              (3) Executive Room – ₦23,000 per night (Max 3 ppl) <br />
              (2) 2 Bedroom Luxury apartments – ₦50,000 per night (Max 7 ppl){" "}
              <br />
              (1) 3 Bedroom Luxury apartments – ₦85,000 per night (Max 10 ppl){" "}
              <br />
              Contact Number- 08025930791 Manager
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              Hotel De Kajola Ajiwe (7mina to venue)
            </h4>
            <p className='mb-9'>
              Available Rooms - 6 <br />
              Standard Room – ₦18,000 per night <br />
              Other Rooms ₦25,000 per night <br />
              Suite: ₦35,000 per night <br />
              Contact Number- 08029757756 Manager
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              Hillsbay Hotel Ajiwe (10mins to venue)
            </h4>
            <p className='mb-9'>
              Price: From ₦25,000 per night <br />
              For reservations 08023024974 Manager
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              Mariskaville hotel (10mins to venue)
            </h4>
            <p className='mb-9'>
              Habitat/Ex room: ₦30,000 per night <br />
              Heaven/St room ₦25,000 per night. <br />
              Cave/mini standard: ₦20,000 per night <br />
              Nest room: ₦15,000 per night. <br />
              08023697970
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              Synergy hotel Abraham Adesanya Ajah.
            </h4>
            <p className='mb-9'>
              Standard room: ₦20,000 per night <br />
              Executive: ₦30,000 per night <br />
              09060001237 Front desk.
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              Bates Hotel 9 Rooms (5mins walk to venue)
            </h4>
            <p className='mb-9'>
              6 Standard Rooms - ₦25,000 <br /> 1 Executive Room - ₦35,000{" "}
              <br /> 1 Luxury Room - ₦20,000 <br /> 1 Semi Standard - ₦16,000{" "}
              <br /> 07046153607 Front desk
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              BUCKERS PALACE HOTEL MOPOL ROAD UNITED ESTATE SANGOTEDO
            </h4>
            <p className='mb-9'>
              Standard = ₦10,000 <br />
              Executive= ₦15,000 <br />
              Deluxe= ₦20,000 <br />
              18 Rooms available <br />
              Contact no: 08062093698.
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              BANGKOK SUITE NO 10 NOSAKHARE STREET,UNITED ESTATE SANGOTEDO. 15
              minutes drive to venue.
            </h4>
            <p className='mb-9'>
              Standard rooms = ₦15,000 <br />
              Superior = ₦18000 <br />
              Deluxe = ₦20,000 <br />
              King Suite = ₦40,000 <br />
              18 Rooms available Call MOSES on 07040729445.
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              MIDWAY RESIDENCE HOTEL NO 1,OKHOMINA NOSAKHARE STREET, OFF MOPO
              RD, UNITED ESTATE SANGOTEDO.
            </h4>
            <p className='mb-9'>
              Standard Rooms ₦15,000 <br />
              Standard Double ₦18,000 <br />
              Deluxe Rooms ₦21,000 <br />
              20 minutes drive from Venue <br />
              Contact: Ayo on 08139560300.
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              CITY NEST HOTEL Thomas Estate
            </h4>
            <p className='mb-9'>
              10 Rooms Available <br />
              Deluxe room: ₦20,000 <br />
              Superior Deluxe room: ₦30,000 <br />
              Contact Number: 08091775577
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              ELDOM HOTEL Budo Peninsula Estate, Ajiwe
            </h4>
            <p className='mb-4'>
              20 Rooms Available <br />
              Single Room: ₦8,000 <br />
              Double Room: ₦8,500 <br />
              Premium Room: ₦10,000 <br />
              Classic Suite: ₦11,500 <br />
              Executive Suite: ₦12,000 <br />
              Contact Number: 08027748496
            </p>
            <br />
            <p>
              In case of any assistance kindly contact DC Lagos Camp Meeting
              Accommodation desk. <br />P Chuks{" "}
              <a href='tel:+2348038821585' className='text-blue-600 underline'>
                +234-8038821585
              </a>{" "}
              <br /> P Tosin{" "}
              <a href='tel:+2348056202723' className='text-blue-600 underline'>
                +234-8056202723
              </a>{" "}
              <br />P Chinedu{" "}
              <a href='tel:+2348151624068' className='text-blue-600 underline'>
                +234-8151624068
              </a>{" "}
              <br />P David{" "}
              <a href='tel:+2349050704070' className='text-blue-600 underline'>
                +234-9050704070
              </a>
            </p>
            <p className='my-5'>
              <strong>CAVEAT</strong> <br /> Please take note that all
              arrangements are directly between you and the hotels. <br /> That
              is why telephone numbers or respective manager and administrators
              have been provided.
              <strong>The Dominion City church</strong> is not responsible for
              any arrangements except the discounted prices hereby provided.{" "}
              <br />
              For further information and/or clarification, Pls ensure that you
              contact the pastors numbers provided.
            </p>
            <p>
              <strong>
                WISHING US ALL AN EXCITING CAMP MEETING 2022 EXPERIENCE
              </strong>
            </p>
          </div>
        </>
      ) : (
        <>
          <div className='flex flex-wrap justify-center text-center sm:mb-10'>
            <div className='w-full lg:w-6/12 px-4'>
              <div className='flex justify-center'>
                <img
                  src={logo}
                  alt='Logo'
                  className='img-fluid max-w-full'
                  style={{
                    height: "10rem",
                    width: "10rem",
                  }}
                />
              </div>
              <h2 className='text-3xl font-semibold'>Enugu Accomodation</h2>
            </div>
          </div>
          <div className='w-full md:w-1/2 ml-auto mr-auto px-4 mb-20'>
            <div className='text-white px-6 py-4 border-0 rounded relative mb-4 bg-blue-500'>
              <span className='inline-block mr-5 align-middle'>
                <i className='fas fa-info-circle'></i>
                <span className='ml-3'>
                  As the countdown to CAMP MEETING 2022 continues, please secure
                  your hotel accommodation.
                </span>
              </span>
            </div>
            <h4 className='font-bold uppercase text-blue-900'>
              HOTEL FIDELMA (7mins to venue)
            </h4>
            <p className='mb-9'>
              08038880099 <br />
              15rooms <br />
              Rates ₦16,000 ₦20,000, ₦25,000
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              BEX HOTEL (15mins to venue)
            </h4>
            <p className='mb-9'>
              Executive room from ₦15,000 <br />
              Contact Number- 08037426437 manager
            </p>

            <h4 className='font-bold uppercase text-blue-900'>
              Ascot Hotel (6 minutes to venue)
            </h4>
            <p className='mb-9'>
              26 Rooms Available <br />
              From ₦8,000 <br />
              Contact Number- 08066268898
            </p>

            <h4 className='font-bold uppercase text-blue-900'>
              Willis Hotel (8mins to venue )
            </h4>
            <p className='mb-9'>
              25 Rooms Available <br />
              From ₦10,000
            </p>

            <h4 className='font-bold uppercase text-blue-900'>
              TGolden villa (5 minutes to Venue)
            </h4>
            <p className='mb-9'>
              5 rooms Available From ₦12,000 <br /> contact 08038880099.
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              Gold Value Hotel (7minutes to Venue.)
            </h4>
            <p className='mb-9'>
              15rooms available From ₦18,000. <br /> Reservation is based on
              payment.
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              Hotel Cordial. (7minutes to venue)
            </h4>
            <p className='mb-9'>
              30 rooms Available <br />
              From ₦8,000
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              Aston Villa hotel and suites. (10minutes from venue)
            </h4>
            <p className='mb-9'>From ₦13,000</p>
            <h4 className='font-bold uppercase text-blue-900'>
              Chester Hotel (10 minutes to venue)
            </h4>
            <p className='mb-9'>Price from ₦12,000</p>
            <h4 className='font-bold uppercase text-blue-900'>
              Newage Hotel (8minutes to venue)
            </h4>
            <p className='mb-9'>From ₦12,000</p>
            <h4 className='font-bold uppercase text-blue-900'>
              Discovery suites (10 minutes to venue)
            </h4>
            <p className='mb-9'>Price From ₦15,000</p>
            <h4 className='font-bold uppercase text-blue-900'>
              Admiral hotel independence layout (12minutes to venue)
            </h4>
            <p className='mb-9'>From ₦12,000</p>
            <h4 className='font-bold uppercase text-blue-900'>
              HOTEL SYLVIA (7 mins to venue)
            </h4>
            <p className='mb-9'>
              From ₦17,000 <br />
              08037419861
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              PETER'S PLACE (5 mins to venue)
            </h4>
            <p className='mb-9'>
              20 rooms (₦10,000 to ₦20,000) <br />
              09136519377
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              HARDBREAK (17 mins to venue)
            </h4>
            <p className='mb-9'>
              15 rooms (₦15,000) <br />
              08032743848
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              GARDEN COURT (7 mins to venue)
            </h4>
            <p className='mb-9'>
              20 rooms <br />
              (₦6,000, ₦8,500, ₦12,000, ₦17,000, ₦24,000) <br />
              08032245229
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              BONTERA HOTEL (20 mins to venue)
            </h4>
            <p className='mb-4'>
              15 rooms <br />
              ₦15,000 <br />
              08035899450
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              PREDIA HOTEL (15 mins to venue)
            </h4>
            <p className='mb-3'>₦18,000 to ₦30,000</p>
            <h4 className='font-bold uppercase text-blue-900'>
              SUNSHINE GUEST HOUSE (15 mins to venue)
            </h4>
            <p className='mb-3'>
              ₦8,000, ₦10,000, ₦13,000 - ₦15,000 rooms <br />
              0803 674 1686
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              HOTEL HIMALAYAS (15 mins to venue)
            </h4>
            <p className='mb-3'>
              ₦8,800 to ₦17,800 <br />
              08033207171
            </p>
            <h4 className='font-bold uppercase text-blue-900'>
              Hostel (5minutes to venue)
            </h4>
            <p className='mb-3'>₦7,000 for the 4 nights</p>
            <h4 className='font-bold uppercase text-blue-900'>
              Anglican guest house 10mins to venue
            </h4>
            <p className='mb-3'>
              Please no wearing of trousers at all. <br />
              Ac rooms ₦7,500 <br />
              Non Ac room ₦5,500
            </p>
            <br />
            <p>
              In case of any assistance kindly contact Dominion City Enugu Camp
              meeting Accomodation desk. <br />
              P. OZ{" "}
              <a href='tel:+2348066268898' className='text-blue-600 underline'>
                +234-8066268898
              </a>{" "}
              <br /> P. OBUM{" "}
              <a href='tel:+2347038808965' className='text-blue-600 underline'>
                +234-7038808965
              </a>{" "}
              <br />
              P. SABASTINE{" "}
              <a href='tel:+2348163218212' className='text-blue-600 underline'>
                +234-8163218212
              </a>{" "}
              <br />
              P. GEORGE{" "}
              <a href='tel:+2348038880099' className='text-blue-600 underline'>
                +234-8038880099
              </a>{" "}
              <br />
              P. EMMA{" "}
              <a href='tel:+2348036760782' className='text-blue-600 underline'>
                +234-8036760782
              </a>
            </p>
            <p className='my-5'>
              <strong>CAVEAT</strong> <br /> Please take note that all
              arrangements are directly between you and the hotels. <br /> That
              is why telephone numbers or respective manager and administrators
              have been provided.
              <strong>The Dominion City church</strong> is not responsible for
              any arrangements except the discounted prices hereby provided.{" "}
              <br />
              For further information and/or clarification, Pls ensure that you
              contact the pastors numbers provided.
            </p>
            <p>
              <strong>
                WISHING US ALL AN EXCITING CAMP MEETING 2022 EXPERIENCE
              </strong>
            </p>
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default Accomodation;
