import React, { useState, useMemo } from "react";
import praying from "../../assets/img/pray.jpeg";
import Select from "react-select";
import countryList from "react-select-country-list";
import { db, timestamp } from "../../firebase/firebase.utils";
import { notify } from "../../utils/notification";
import { iNotification } from "react-notifications-component";

const PrayerRequest = () => {
  const [country, setCountry] = useState<any>("");
  const [formData, setData] = useState({
    fullName: "",
    email: "",
    request: "",
    contact: "",
  });
  const [isSaving, setIsSaving] = useState(false);
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value: any) => {
    setCountry(value);
  };

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;

    setData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!country) {
      notify({
        message: `All form fields are required`,
        type: "danger",
      } as iNotification);
      return;
    }

    try {
      setIsSaving(true);
      const createdAt = timestamp();
      await db
        .collection("prayerRequest")
        .add({ country: country ? country.label : "", ...formData, createdAt });
      notify({
        message: `Prayer request sent successfully. We will be praying with you`,
        type: "success",
      } as iNotification);
      setIsSaving(false);
      setData({
        fullName: "",
        email: "",
        request: "",
        contact: "",
      });
      setCountry("");
    } catch (error: any) {
      if (error.message) {
        notify({
          message: `${error.message}`,
          type: "danger",
        } as iNotification);
      } else {
        notify({
          message: `Error performing operation. Please try again`,
          type: "danger",
        } as iNotification);
      }
      setIsSaving(false);
    }
  };

  const { fullName, email, request, contact } = formData;

  const saving = (message: string) => {
    return (
      <div className='text-sm text-center text-blue-900'>{message}...</div>
    );
  };

  return (
    <div className='pb-20 relative block sm:mt-32 mt-8 bg-blue-900'>
      <div
        className='bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20'
        style={{ transform: "translateZ(0)" }}
      >
        <svg
          className='absolute bottom-0 overflow-hidden'
          xmlns='http://www.w3.org/2000/svg'
          preserveAspectRatio='none'
          version='1.1'
          viewBox='0 0 2560 100'
          x='0'
          y='0'
        >
          <polygon
            className='text-blue-900 fill-current'
            points='2560 0 2560 100 0 100'
          ></polygon>
        </svg>
      </div>
      <div className='flex sm:flex-nowrap flex-wrap items-center sm:mt-32 mt-8 mb-10 bg-blue-900 sm:p-10'>
        <div className='w-full sm:px-4 mr-auto ml-auto '>
          <div className='relative flex flex-col min-w-0 break-words w-full  bg-gray-300'>
            <form onSubmit={handleSubmit}>
              <div className='flex-auto p-5 lg:p-10'>
                <h4 className='text-2xl font-semibold '>
                  Have a Prayer Request ?
                </h4>
                <p className='leading-relaxed mt-1 mb-4 '>
                  Let us pray with you.
                </p>
                {isSaving ? saving("Sending prayer request") : null}
                <div className='relative w-full mb-3 mt-8'>
                  <label
                    className='block uppercase  text-xs font-bold mb-2'
                    htmlFor='full-name'
                  >
                    Full Name
                  </label>
                  <input
                    type='text'
                    id='full-name'
                    className='px-3 py-3 placeholder-gray-400 text-gray-700  bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                    placeholder='Full Name'
                    required
                    name='fullName'
                    value={fullName}
                    onChange={onChange}
                  />
                </div>

                <div className='relative w-full mb-3'>
                  <label
                    className='block uppercase text-xs font-bold mb-2'
                    htmlFor='email'
                  >
                    Email
                  </label>
                  <input
                    type='email'
                    id='email'
                    className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                    placeholder='Email'
                    required
                    name='email'
                    value={email}
                    onChange={onChange}
                  />
                </div>
                <div className='relative w-full mb-3'>
                  <label
                    className='block uppercase text-xs font-bold mb-2'
                    htmlFor='contact'
                  >
                    Contact(Mobile)
                  </label>
                  <input
                    type='text'
                    className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                    placeholder='Contact number'
                    name='contact'
                    id='contact'
                    required
                    value={contact}
                    onChange={onChange}
                  />
                  <small className='text-xs'>
                    Please add country code e.g (+234)
                  </small>
                </div>
                <div className='relative w-full mb-3'>
                  <label
                    className='block uppercase text-xs font-bold mb-2'
                    htmlFor='country'
                  >
                    Country
                  </label>

                  <Select
                    options={options}
                    value={country}
                    isClearable
                    id='country'
                    isSearchable
                    placeholder='Select country'
                    onChange={changeHandler}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        // primary25: "#9fa6b2",
                        primary: "#1a56db",
                      },
                    })}
                  />
                </div>

                <div className='relative w-full mb-3'>
                  <label
                    className='block uppercase  text-xs font-bold mb-2'
                    htmlFor='message'
                  >
                    Prayer Request
                  </label>
                  <textarea
                    id='message'
                    rows={4}
                    cols={80}
                    className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full'
                    placeholder='Type request...'
                    required
                    name='request'
                    value={request}
                    onChange={onChange}
                  />
                </div>
                <div className='text-center mt-6'>
                  <button
                    className='bg-blue-900 text-white active:bg-gray-300 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none hover:bg-blue-800 focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='submit'
                    style={{ backgroundColor: "#b0940c", color: "#fff" }}
                  >
                    Send Request
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className='w-full mr-auto ml-auto'>
          <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded-lg'>
            <img
              alt='Prayer'
              src={praying}
              className='w-full align-middle shadow-lg'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrayerRequest;
