import React, { useMemo, useState } from "react";
import praying from "../../assets/church-project.jpg";
import Select from "react-select";
import countryList from "react-select-country-list";
import CurrencyList from "currency-list";
import { CurrencyObject, flattenCurrencyObject } from "../../utils";
import { notify } from "../../utils/notification";
import { iNotification } from "react-notifications-component";
import { db, projectStorage, timestamp } from "../../firebase/firebase.utils";
import { Link } from "react-router-dom";

const categoriesData = {
  pastorToBuild: "Pastor to build",
  landDonor: "Land donor",
  donorToBuild: "Building Funder",
};

const initialState = {
  state: "",
  city: "",
  chapterName: "",
  chapterPastor: "",
  chapterPastorNumber: "",
  chapterPastorEmail: "",
  landSize: "",
  landLocation: "",
  docType: "",
  proposedLocation: "",
  proposedSize: "",
  donorFullName: "",
  donorNumber: "",
  donorLandSize: "",
  donorLandLocation: "",
  donorLandDoc: "",
  donorToBuilidFullName: "",
  donorToBuilidNumber: "",
  donorToBuilidAmount: "",
  funderEmail: "",
  donorEmail: "",
};

const BuildingProject = () => {
  const [country, setCountry] = useState<any>("");
  const [category, setCategory] = useState<any>("");
  const [fileError, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [haveLand, setHaveLand] = useState("");
  const [currency, setCurrency] = useState<any>("");
  const [formState, setFormState] = useState(initialState);
  const [filesUrl, setUrl] = useState<{ url: string; type: string }[]>([]);
  const options = useMemo(() => countryList().getData(), []);

  const currencies = useMemo(
    () => flattenCurrencyObject(CurrencyList.getAll("en_US") as CurrencyObject),
    []
  );

  const changeHandler = (value: any) => {
    setCountry(value);
  };

  const handleUplaod = (files: FileList) => {
    if (files && files.length) {
      let count = 0;
      const uploads: { url: string; type: string }[] = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        setError("");
        count++;
        const isLast = count === files.length;
        if (file) {
          const storageRef = projectStorage.ref(file.name);

          storageRef.put(file).on(
            "state_changed",
            (snap) => {
              let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
              setProgress(percentage);
            },
            (err) => {
              if (err.message) {
                notify({
                  message: `${err.message}`,
                  type: "danger",
                } as iNotification);
              } else {
                notify({
                  message: `Error uploading image. Please try again`,
                  type: "danger",
                } as iNotification);
              }
            },
            async () => {
              const url = await storageRef.getDownloadURL();
              uploads.push({ url, type: file.type });
              if (!isLast) {
                setProgress(0);
              }
              if (isLast) {
                notify({
                  message: `File(s) uploaded successfully`,
                  type: "success",
                } as iNotification);
              }
            }
          );
        } else {
          return;
        }
      }

      setUrl(uploads);
    } else {
      return;
    }

    // references
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!country) {
      notify({
        message: `Please select country`,
        type: "danger",
      } as iNotification);
      return;
    }

    if (!category) {
      notify({
        message: `Please select category`,
        type: "danger",
      } as iNotification);
      return;
    }

    if (category?.value === categoriesData.pastorToBuild && !haveLand) {
      notify({
        message: `Please answer question "Have Land ?" `,
        type: "danger",
      } as iNotification);
      return;
    }

    if (category?.value === categoriesData.donorToBuild && !currency) {
      notify({
        message: `Please select currency `,
        type: "danger",
      } as iNotification);
      return;
    }

    try {
      setIsSaving(true);
      const createdAt = timestamp();
      await db.collection("churchBuildingProject").add({
        country: country ? country.label : "",
        category: category ? category.value : "",
        currency: currency ? currency.name : "",
        haveLand,
        ...formState,
        file: filesUrl,
        createdAt,
      });
      notify({
        message: `Record sent successfully. God bless you!`,
        type: "success",
      } as iNotification);
      setIsSaving(false);
      setFormState(initialState);
      setCountry("");
      setUrl([]);
      setCategory("");
      setCurrency("");
      setProgress(0);
      setError(null);
      setHaveLand("");
    } catch (error: any) {
      if (error.message) {
        notify({
          message: `${error.message}`,
          type: "danger",
        } as iNotification);
      } else {
        notify({
          message: `Error performing operation. Please try again`,
          type: "danger",
        } as iNotification);
      }
      setIsSaving(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selected: FileList | null = null;

    const { files } = e.target;

    if (files) {
      selected = files;
    }

    if (selected && selected.length > 5) {
      setError("You can only upload maximum of 5 files at once");
      return;
    } else {
      if (selected && selected.length) {
        handleUplaod(selected);
      }
    }
  };

  const uploadProgress = () => (
    <div className='relative pt-1'>
      <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200'>
        <div
          style={{ width: `${progress}%` }}
          className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500'
        ></div>
      </div>
    </div>
  );

  const saving = (message: string) => {
    return (
      <div className='text-sm text-center text-gray-400'>{message}...</div>
    );
  };

  return (
    <div className='flex sm:flex-nowrap  items-center sm:flex-row flex-wrap-reverse mb-10 sm:p-10 bg-gray-300'>
      <div className='w-full sm:px-4 mr-auto ml-auto'>
        <div className='relative flex flex-col min-w-0 break-words w-full bg-gray-100'>
          <form onSubmit={handleSave}>
            <div className='flex-auto p-5 lg:p-10'>
              <h4 className='text-2xl font-semibold text-center'>
                1000 Church Building Project
              </h4>
              <div className='flex justify-around sm:mt-10 mt-3 sm:flex-row flex-col'>
                <div>
                  <h4 className='font-bold'>Contact</h4>
                  <p className='leading-relaxed mt-1  text-sm flex flex-col'>
                    <a
                      href='tel:+2348035508230'
                      className=' text-blue-600 hover:underline'
                    >
                      +234-8035508230
                    </a>
                    <a
                      href='tel:+2348034954566'
                      className=' text-blue-600 hover:underline'
                    >
                      +234-8034954566
                    </a>
                    <a
                      className=' text-blue-600 hover:underline'
                      href='mailto:projects@dominioncity.cc'
                    >
                      projects@dominioncity.cc
                    </a>
                  </p>
                </div>
                <div>
                  <h4 className='font-bold'>Account Numbers</h4>
                  <p className='text-sm mb-4'>
                    1000 CHURCH PROJECT <br /> GTBANK <br /> Dominion City{" "}
                    <br /> <strong>NGN:</strong>
                    0283265571 <br /> <strong>USD:</strong> 0283265588 <br />{" "}
                    <strong>GBP:</strong> 0283265595 <br />{" "}
                    <strong>EUR:</strong> 0283266389 <br />{" "}
                    <strong>SORT CODE:</strong>
                    058152405 <br /> <strong>SWIFT CODE:</strong> GTBINGLA
                  </p>
                  {/* <Link
                    to={`/church-building-projects-payments`}
                    className='bg-white border border-blue-900 text-blue-900 active:bg-gray-100 text-xs font-bold uppercase px-6 py-3 rounded shadow hover:shadow-md hover:bg-blue-900 hover:text-white outline-none focus:outline-none mb-9 ease-linear transition-all duration-150 text-center'
                  >
                    Card Payment
                  </Link> */}
                </div>
              </div>
              {isSaving ? saving("Sending data") : null}

              <h4 className='font-bold mt-8 mb-3'>Project Form</h4>
              <div className='relative w-full mb-3'>
                <label
                  className='block uppercase text-xs font-bold mb-2'
                  htmlFor='country'
                >
                  Country
                </label>

                <Select
                  options={options}
                  value={country}
                  isClearable
                  inputId='country'
                  isSearchable
                  placeholder='Select country'
                  onChange={changeHandler}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      // primary25: "#9fa6b2",
                      primary: "#1a56db",
                    },
                  })}
                />
              </div>

              <div className='relative w-full mb-3'>
                <label
                  className='block uppercase  text-xs font-bold mb-2'
                  htmlFor='state'
                >
                  State
                </label>
                <input
                  type='text'
                  id='state'
                  className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                  placeholder='State'
                  required
                  name='state'
                  value={formState.state}
                  onChange={(e) =>
                    setFormState((prev) => ({ ...prev, state: e.target.value }))
                  }
                />
              </div>
              <div className='relative w-full mb-3'>
                <label
                  className='block uppercase  text-xs font-bold mb-2'
                  htmlFor='city'
                >
                  City
                </label>
                <input
                  type='text'
                  id='city'
                  className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                  placeholder='City'
                  required
                  name='city'
                  value={formState.city}
                  onChange={(e) =>
                    setFormState((prev) => ({ ...prev, city: e.target.value }))
                  }
                />
              </div>
              <div className='relative w-full mb-3'>
                <label
                  className='block uppercase text-xs font-bold mb-2'
                  htmlFor='category'
                >
                  Categories
                </label>

                <Select
                  options={[
                    {
                      label: "A Pastor who wants to build",
                      value: categoriesData.pastorToBuild,
                    },
                    {
                      label: "You want to donate a land/property",
                      value: categoriesData.landDonor,
                    },
                    {
                      label: "Someone who wants to fund a building project",
                      value: categoriesData.donorToBuild,
                    },
                  ]}
                  value={category}
                  isClearable
                  inputId='category'
                  isSearchable
                  placeholder='Select category'
                  onChange={(value) => setCategory(value)}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      // primary25: "#9fa6b2",
                      primary: "#1a56db",
                    },
                  })}
                />
              </div>

              {category?.value === categoriesData.pastorToBuild ? (
                <>
                  <small className='font-semibold block py-3 underline uppercase'>
                    PASTOR TO BUILD CATEGORY
                  </small>
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='chapter-name'
                    >
                      Chapter Name
                    </label>
                    <input
                      type='text'
                      id='chapter-name'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Chapter Name'
                      required
                      name='chapterName'
                      value={formState.chapterName}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          chapterName: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='chapter-pastor'
                    >
                      Chapter Pastor
                    </label>
                    <input
                      type='text'
                      id='chapter-pastor'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Chapter Pastor'
                      required
                      name='chapterPastor'
                      value={formState.chapterPastor}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          chapterPastor: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className='relative w-full mb-3 '>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='chapterPastorNumber'
                    >
                      Chapter Pastor Number
                    </label>
                    <input
                      type='text'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Chapter Pastor Number'
                      name='chapterPastorNumber'
                      id='chapterPastorNumber'
                      required
                      value={formState.chapterPastorNumber}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          chapterPastorNumber: e.target.value,
                        }))
                      }
                    />
                    <small className=' text-xs'>
                      Please add country code (Example: +2348032345678)
                    </small>
                  </div>
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='chapter-email'
                    >
                      Email
                    </label>
                    <input
                      type='email'
                      id='chapter-email'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Email'
                      required
                      name='chapterPastorEmail'
                      value={formState.chapterPastorEmail}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          chapterPastorEmail: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className='relative w-full mb-3'>
                    <label className='block uppercase  text-xs font-bold mb-2'>
                      Have Land ?
                    </label>
                    <label className='inline-flex items-center'>
                      <input
                        type='radio'
                        className='form-radio text-blue-800'
                        name='haveLand'
                        checked={haveLand === "Yes"}
                        onChange={() => setHaveLand("Yes")}
                      />
                      <span className='ml-2 text-base'>Yes</span>
                    </label>

                    <label className='inline-flex items-center ml-4'>
                      <input
                        type='radio'
                        className='form-radio text-blue-800'
                        name='haveLand'
                        checked={haveLand === "No"}
                        onChange={() => setHaveLand("No")}
                      />
                      <span className='ml-2 text-base'>No</span>
                    </label>
                  </div>

                  {haveLand === "Yes" ? (
                    <>
                      <div className='relative w-full mb-3'>
                        <label
                          className='block uppercase  text-xs font-bold mb-2'
                          htmlFor='landSize'
                        >
                          Size
                        </label>
                        <input
                          type='text'
                          id='landSize'
                          className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                          placeholder='Size'
                          required
                          name='landSize'
                          value={formState.landSize}
                          onChange={(e) =>
                            setFormState((prev) => ({
                              ...prev,
                              landSize: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className='relative w-full mb-3'>
                        <label
                          className='block uppercase  text-xs font-bold mb-2'
                          htmlFor='landLocation'
                        >
                          Location
                        </label>
                        <input
                          type='text'
                          id='landLocation'
                          className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                          placeholder='Location'
                          required
                          name='landLocation'
                          value={formState.landLocation}
                          onChange={(e) =>
                            setFormState((prev) => ({
                              ...prev,
                              landLocation: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className='relative w-full mb-3'>
                        <label
                          className='block uppercase  text-xs font-bold mb-2'
                          htmlFor='docType'
                        >
                          Type of land document available
                        </label>
                        <input
                          type='text'
                          id='docType'
                          className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                          placeholder='Document type'
                          required
                          name='docType'
                          value={formState.docType}
                          onChange={(e) =>
                            setFormState((prev) => ({
                              ...prev,
                              docType: e.target.value,
                            }))
                          }
                        />
                      </div>
                      {progress ? uploadProgress() : null}
                      {fileError ? (
                        <small className='text-red-400 text-xs'>
                          {fileError}
                        </small>
                      ) : null}
                      <div className='relative w-full mb-3'>
                        <label
                          className='block uppercase  text-xs font-bold mb-2'
                          htmlFor='buildingDesign'
                        >
                          Building design
                        </label>
                        <input
                          type='file'
                          accept='.jpg,.jpeg,.png,.pdf,.doc,.docx'
                          multiple
                          id='buildingDesign'
                          className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                          placeholder='Building design'
                          name='buildingDesign'
                          onChange={handleFileChange}
                        />
                        <small className='text-xs'>
                          Upload building design in .jpg,.jpeg,.png,.pdf,.doc,
                          or .docx format
                        </small>
                      </div>
                    </>
                  ) : null}

                  {haveLand === "No" ? (
                    <>
                      <div className='relative w-full mb-3'>
                        <label
                          className='block uppercase  text-xs font-bold mb-2'
                          htmlFor='proposedLocation'
                        >
                          Proposed location
                        </label>
                        <input
                          type='text'
                          id='proposedLocation'
                          className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                          placeholder='Proposed location'
                          required
                          name='proposedLocation'
                          value={formState.proposedLocation}
                          onChange={(e) =>
                            setFormState((prev) => ({
                              ...prev,
                              proposedLocation: e.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className='relative w-full mb-3'>
                        <label
                          className='block uppercase  text-xs font-bold mb-2'
                          htmlFor='proposedSize'
                        >
                          Proposed size
                        </label>
                        <input
                          type='text'
                          id='proposedSize'
                          className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                          placeholder='Proposed size'
                          required
                          name='proposedSize'
                          value={formState.proposedSize}
                          onChange={(e) =>
                            setFormState((prev) => ({
                              ...prev,
                              proposedSize: e.target.value,
                            }))
                          }
                        />
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}

              {category?.value === categoriesData.landDonor ? (
                <>
                  <small className='font-semibold block py-3 underline uppercase'>
                    Land / Property / Building Donor Category
                  </small>
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='donorFullName'
                    >
                      Full Name
                    </label>
                    <input
                      type='text'
                      id='donorFullName'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Full Name'
                      required
                      name='donorFullName'
                      value={formState.donorFullName}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          donorFullName: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className='relative w-full mb-3 '>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='donorNumber'
                    >
                      Mobile Number
                    </label>
                    <input
                      type='text'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Mobile Number'
                      name='donorNumber'
                      id='donorNumber'
                      required
                      value={formState.donorNumber}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          donorNumber: e.target.value,
                        }))
                      }
                    />
                    <small className=' text-xs'>
                      Please add country code (Example: +2348032345678)
                    </small>
                  </div>
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='donorEmail'
                    >
                      Email
                    </label>
                    <input
                      type='email'
                      id='donorEmail'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Email'
                      required
                      name='donorEmail'
                      value={formState.donorEmail}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          donorEmail: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='donorLandSize'
                    >
                      Size
                    </label>
                    <input
                      type='text'
                      id='donorLandSize'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Size'
                      required
                      name='donorLandSize'
                      value={formState.donorLandSize}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          donorLandSize: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='donorLandLocation'
                    >
                      Location
                    </label>
                    <input
                      type='text'
                      id='donorLandLocation'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Location'
                      required
                      name='donorLandLocation'
                      value={formState.donorLandLocation}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          donorLandLocation: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='donorLandDoc'
                    >
                      Type of land/property document available
                    </label>
                    <input
                      type='text'
                      id='donorLandDoc'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Type of land/property document available'
                      required
                      name='donorLandDoc'
                      value={formState.donorLandDoc}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          donorLandDoc: e.target.value,
                        }))
                      }
                    />
                  </div>
                </>
              ) : null}

              {category?.value === categoriesData.donorToBuild ? (
                <>
                  <small className='font-semibold block py-3 underline uppercase'>
                    Funder Category
                  </small>
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='donorToBuilidFullName'
                    >
                      Full Name
                    </label>
                    <input
                      type='text'
                      id='donorToBuilidFullName'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Full Name'
                      required
                      name='donorToBuilidFullName'
                      value={formState.donorToBuilidFullName}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          donorToBuilidFullName: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className='relative w-full mb-3 '>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='donorToBuilidNumber'
                    >
                      Mobile Number
                    </label>
                    <input
                      type='text'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Mobile Number'
                      name='donorToBuilidNumber'
                      id='donorToBuilidNumber'
                      required
                      value={formState.donorToBuilidNumber}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          donorToBuilidNumber: e.target.value,
                        }))
                      }
                    />
                    <small className=' text-xs'>
                      Please add country code (Example: +2348032345678)
                    </small>
                  </div>
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='funderEmail'
                    >
                      Email
                    </label>
                    <input
                      type='email'
                      id='funderEmail'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Email'
                      required
                      name='funderEmail'
                      value={formState.funderEmail}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          funderEmail: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase  text-xs font-bold mb-2'
                      htmlFor='donorToBuilidAmount'
                    >
                      Amount
                    </label>
                    <input
                      type='number'
                      id='donorToBuilidAmount'
                      className='px-3 py-2.5 placeholder-gray-400 text-gray-700  bg-white rounded text-sm focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150 border-gray-300'
                      placeholder='Amount'
                      required
                      name='donorToBuilidAmount'
                      min={1}
                      value={formState.donorToBuilidAmount}
                      onChange={(e) =>
                        setFormState((prev) => ({
                          ...prev,
                          donorToBuilidAmount: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase text-xs font-bold mb-2'
                      htmlFor='currency'
                    >
                      Currency
                    </label>

                    <Select
                      options={currencies}
                      value={currency}
                      isClearable
                      inputId='currency'
                      isSearchable
                      placeholder='Select currency'
                      onChange={(value) => setCurrency(value)}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          // primary25: "#9fa6b2",
                          primary: "#1a56db",
                        },
                      })}
                      getOptionLabel={(op) => op.name}
                      getOptionValue={(op) => op.name}
                    />
                  </div>
                </>
              ) : null}

              <div className='text-center mt-6'>
                <button
                  className='bg-blue-900 text-white active:bg-gray-300 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none hover:bg-blue-800 focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                  type='submit'
                  // style={{ backgroundColor: "#b0940c", color: "#fff" }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='w-full'>
        <div className='relative flex flex-col min-w-0 break-words bg-white w-full rounded-lg mb-5'>
          <img
            alt='Prayer'
            src={praying}
            className='w-full align-middle shadow-lg'
          />
        </div>
        <div className='flex justify-center'>
          <Link
            to={`/church-building-projects`}
            className='bg-blue-900 text-white active:bg-gray-100 text-xs font-bold uppercase px-6 py-3 rounded shadow hover:shadow-md hover:bg-blue-500 outline-none focus:outline-none mb-9 ease-linear transition-all duration-150 text-center'
          >
            View Projects
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BuildingProject;
