import React, { useEffect } from "react";
import logo from "../assets/DC-Logo's/dc-logo-1024.png";
import Footer from "../components/Footers/Footer";
import General from "../components/Forms/General";
import Navbar from "../components/Navbars/AuthNavbar";

interface OnlineFormPageProps {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const OnlineFormPage: React.FC<OnlineFormPageProps> = ({
  setCurrentLocation,
  pathname,
}) => {
  useEffect(() => {
    setCurrentLocation("/online-forms");
  }, []);
  return (
    <React.Fragment>
      <Navbar pathname={pathname} />
      <div className='sm:pt-10 mt-9 mb-16'>
        <div className='flex flex-wrap justify-center text-center sm:mb-10'>
          <div className='w-full lg:w-6/12 px-4'>
            <div className='flex justify-center'>
              <img
                src={logo}
                alt='Logo'
                className='img-fluid max-w-full'
                style={{
                  height: "10rem",
                  width: "10rem",
                }}
              />
            </div>
            <h2 className='text-3xl font-semibold sm:mb-0 mb-3'>
              Online Forms
            </h2>
          </div>
        </div>
        <General />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default OnlineFormPage;
