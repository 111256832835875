import React, { useState, useEffect } from "react";
import PrayerRequests from "./PrayerRequests";

import Testimony from "./Testimony";

interface AdminTabProps {
  selectedImg: string | null;
  setSelectedImg: (value: React.SetStateAction<string | null>) => void;
  isAuthenticated: {
    admin: boolean;
    testify: boolean;
    prayer: boolean;
  };
}

const AdminTab: React.FC<AdminTabProps> = ({
  isAuthenticated,
  setSelectedImg,
}) => {
  const [openTab, setOpenTab] = useState(1);

  useEffect(() => {
    if (isAuthenticated.prayer && !isAuthenticated.admin) {
      setOpenTab(2);
    } else if (isAuthenticated.testify && !isAuthenticated.admin) {
      setOpenTab(1);
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            {isAuthenticated.admin || isAuthenticated.testify ? (
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 1
                      ? "text-white bg-blue-900"
                      : "text-blue-900 bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Testimonies
                </a>
              </li>
            ) : null}
            {isAuthenticated.admin || isAuthenticated.prayer ? (
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 2
                      ? "text-white bg-blue-900"
                      : "text-blue-900 bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  Prayer Requests
                </a>
              </li>
            ) : null}
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                {isAuthenticated.admin || isAuthenticated.testify ? (
                  <div
                    className={openTab === 1 ? "block" : "hidden"}
                    id="link1"
                  >
                    <Testimony setSelectedImg={setSelectedImg} />
                  </div>
                ) : null}
                {isAuthenticated.admin || isAuthenticated.prayer ? (
                  <div
                    className={openTab === 2 ? "block" : "hidden"}
                    id="link2"
                  >
                    <PrayerRequests />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminTab;
