import React from "react";
import DataGrid, {
  Column,
  ColumnChooser,
  Pager,
  Paging,
  SearchPanel,
  HeaderFilter,
  FilterRow,
  Selection,
  Export,
} from "devextreme-react/data-grid";
import useFirestore from "../../hooks/useFirestore";
import { Spinner } from "../common/Spinner";

const FormSubmissions = ({ type }) => {
  const { docs, isLoading } = useFirestore(
    "generalFormSubmission",
    "type",
    type
  );
  return (
    <div className='container mx-auto mb-12'>
      {isLoading ? (
        <Spinner />
      ) : (
        <DataGrid
          dataSource={docs}
          showBorders={true}
          showRowLines={true}
          showColumnLines={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          rowAlternationEnabled={true}
          hoverStateEnabled={true}
          wordWrapEnabled={true}
          columnHidingEnabled={true}
        >
          <Selection
            mode='multiple'
            selectAllMode='page'
            showCheckBoxesMode='always'
          />

          <HeaderFilter allowSearch={true} visible={true} />

          <FilterRow visible={true} applyFilter='auto' />

          <SearchPanel
            visible={true}
            searchVisibleColumnsOnly={true}
            highlightSearchText={true}
          />
          <ColumnChooser enabled={true} mode='select' allowSearch={true} />

          <Export
            enabled={true}
            excelWrapTextEnabled={true}
            fileName='Forms'
            allowExportSelectedData={true}
          />

          <Paging defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[5, 10, 20]}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Column caption='Name' dataField='fullName' />
          <Column caption='Email' dataField='email' />
          <Column caption='Contact(Mobile)' dataField='contact' />
          <Column caption='Country' dataField='country' />
          <Column caption='Form Type' dataField='type' />
          <Column caption='Comment' dataField='comment' />
        </DataGrid>
      )}
    </div>
  );
};

export default FormSubmissions;
