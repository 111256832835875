import React, { useEffect } from "react";
import Footer from "../components/Footers/Footer";
import BankTransfer from "../components/Give/BankTransfer";
import Navbar from "../components/Navbars/AuthNavbar";

interface BankPageProps {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const BankPage: React.FC<BankPageProps> = ({
  setCurrentLocation,
  pathname,
}) => {
  useEffect(() => {
    setCurrentLocation("/give/bank-transfer");
  }, []);

  const searchParams = new URLSearchParams(window.location.search);

  const isWebView = searchParams.get("webview");

  return (
    <React.Fragment>
      {!isWebView ? <Navbar pathname={pathname} /> : null}
      <div className='sm:pt-10 mt-9'>
        <BankTransfer />
      </div>
      {!isWebView ? <Footer /> : null}
    </React.Fragment>
  );
};

export default BankPage;
