import { useState, useEffect } from "react";
import { db } from "../firebase/firebase.utils";

const useFirestore = (
  collection: string,
  filterPath?: string,
  filterValue?: string
) => {
  const [docs, setDocs] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let unsub: any;

    if (filterPath && filterValue) {
      unsub = db
        .collection(collection)
        .where(filterPath, "==", filterValue)
        .orderBy("createdAt", "desc")
        .onSnapshot((snap) => {
          let documents: any[] = [];
          snap.forEach((doc) => {
            documents.push({ ...doc.data(), id: doc.id });
          });
          setDocs(documents);
          setLoading(false);
        });
    } else {
      unsub = db
        .collection(collection)
        .orderBy("createdAt", "desc")
        .onSnapshot((snap) => {
          let documents: any[] = [];
          snap.forEach((doc) => {
            documents.push({ ...doc.data(), id: doc.id });
          });
          setDocs(documents);
          setLoading(false);
        });
    }

    return () => unsub();
    // this is a cleanup function that react will run when
    // a component using the hook unmounts
  }, [collection]);

  return { docs, isLoading };
};

export default useFirestore;
