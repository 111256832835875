import React, { useEffect } from "react";
import Footer from "../components/Footers/Footer";
import Navbar from "../components/Navbars/AuthNavbar";
import BuildingProject from "../components/BuildingProject/BuildingProject";

interface Props {
  setCurrentLocation: (location: string) => void;
  pathname: string;
}

const BuildingProjectPage: React.FC<Props> = ({
  setCurrentLocation,
  pathname,
}) => {
  useEffect(() => {
    setCurrentLocation("/church-project");
  }, []);

  return (
    <React.Fragment>
      <Navbar pathname={pathname} />
      <div className='sm:py-24 pt-14'>
        <BuildingProject />
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default BuildingProjectPage;
