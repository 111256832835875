import React, { useState, useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import { notify } from "../../utils/notification";
import { iNotification } from "react-notifications-component";
import { db, timestamp } from "../../firebase/firebase.utils";

interface MissionsProps {}

interface MissionsData {
  fullName: string;
  email: string;
  contact: string;
  country: string;
  state: string;
  comment: string;
}

const initialState: MissionsData = {
  fullName: "",
  email: "",
  contact: "",
  country: "",
  state: "",
  comment: "",
};

const Missions: React.FC<MissionsProps> = ({}) => {
  const [country, setCountry] = useState<any>("");
  const [formData, setData] = useState<MissionsData>(initialState);

  const options = useMemo(() => countryList().getData(), []);

  const { contact, email, state, fullName, comment } = formData;

  //   missions

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setData({ ...formData, [name]: value });
  };

  const changeHandler = (value: any) => {
    setCountry(value);
    if (value) {
      setData({ ...formData, country: value.label });
    } else {
      setData({ ...formData, country: "" });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!formData.country) {
      notify({
        message: `All form fields are required`,
        type: "danger",
      } as iNotification);
      return;
    }
    let newObj = JSON.parse(JSON.stringify(formData)) as MissionsData;
    saveToDB(newObj);
  };

  const saveToDB = (formData: MissionsData) => {
    db.collection("missions")
      .add({
        fullName: formData.fullName,
        email: formData.email,
        createdAt: timestamp(),
        country: formData.country,
        state: formData.state,
        contact: formData.contact,
        comment: formData.comment,
      })
      .then(() => {
        notify({
          message: `Contact received, God bless you!!`,
          type: "success",
        } as iNotification);
        setData(initialState);
      })
      .catch((err) => {
        notify({
          message: `Error saving contact. Please try again`,
          type: "danger",
        } as iNotification);
      });
  };

  return (
    <div className='w-full md:w-5/12 ml-auto mr-auto px-4 mb-5'>
      <form onSubmit={handleSubmit}>
        <div className=' '>
          <div className='relative flex flex-col min-w-0 break-words w-full shadow-lg bg-blue-900'>
            <div className='flex-auto p-5 lg:p-10'>
              {/* <h4 className="text-2xl text-white font-semibold">
                Have a Message ?
              </h4>
              <p className="leading-relaxed mt-1 mb-4 text-gray-300">
                Reach us with your question.
              </p> */}
              <div className='relative w-full mb-3 mt-8'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='name'
                >
                  Full Name
                </label>
                <input
                  type='text'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='Full Name'
                  name='fullName'
                  id='name'
                  required
                  value={fullName}
                  onChange={onChange}
                />
              </div>

              <div className='relative w-full mb-3 mt-8'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='contact'
                >
                  Contact(Mobile)
                </label>
                <input
                  type='text'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='Contact number'
                  name='contact'
                  id='contact'
                  required
                  value={contact}
                  onChange={onChange}
                />
                <small className='text-gray-300 text-xs'>
                  Please add country code e.g (+234)
                </small>
              </div>

              <div className='relative w-full mb-3'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='email'
                >
                  Email
                </label>
                <input
                  type='email'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='Email'
                  name='email'
                  id='email'
                  value={email}
                  onChange={onChange}
                  required
                />
              </div>

              <div className='relative w-full mb-3 mt-5'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='country'
                >
                  Country
                </label>

                <Select
                  options={options}
                  value={country}
                  isClearable
                  isSearchable
                  placeholder='Select country'
                  onChange={changeHandler}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      // primary25: "#9fa6b2",
                      primary: "#1a56db",
                    },
                  })}
                />
              </div>

              <div className='relative w-full mb-3 mt-8'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='state'
                >
                  State
                </label>
                <input
                  type='text'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150'
                  placeholder='State'
                  name='state'
                  id='state'
                  value={state}
                  onChange={onChange}
                  required
                />
              </div>
              <div className='relative w-full mb-3'>
                <label
                  className='block uppercase text-gray-300 text-xs font-bold mb-2'
                  htmlFor='message'
                >
                  Comment
                </label>
                <textarea
                  rows={4}
                  cols={80}
                  id='message'
                  className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full'
                  placeholder='Leave a comment...'
                  required
                  onChange={onChange}
                  value={comment}
                  name='comment'
                />
              </div>

              <div className='text-center mt-6'>
                <button
                  className='bg-white text-blue-900 active:bg-gray-300 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none hover:bg-gray-300 focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                  type='submit'
                  style={{ backgroundColor: "#b0940c", color: "#fff" }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Missions;
