import React, { useState } from "react";
import { notify } from "../../utils/notification";
import { iNotification } from "react-notifications-component";
import { db, timestamp } from "../../firebase/firebase.utils";

interface CtaProps {
  isTop: boolean;
}

const Cta: React.FC<CtaProps> = ({ isTop }) => {
  const [email, setEmail] = useState("");

  const saveEmail = () => {
    if (!email) return;

    db.collection("newsletter")
      .add({
        email,
        createdAt: timestamp(),
      })
      .then(() => {
        notify({
          message: `Thank you for subscribing to our mailing list. We will keep you updated with events`,
          type: "success",
        } as iNotification);
        setEmail("");
      })
      .catch((err) => {
        notify({
          message: `There was an issue with your email submission. Please check the email and try again`,
          type: "danger",
        } as iNotification);
      });
  };

  return (
    <section
      className={
        isTop
          ? "pb-10 flex sm:-mt-9 -mt-9 bg-white c-ta "
          : "pb-10 flex sm:mt-9 mt-5 bg-white c-ta"
      }
    >
      <div
        className='flex sm:flex-row flex-col container mx-auto px-4 py-4 items-center justify-center sm:rounded-full rounded-none shadow-lg'
        style={{ backgroundColor: "#b0940c" }}
      >
        <h1 className='mr-3 sm:mb-0 mb-2 font-bold sm:text-xl text-base uppercase'>
          Want to stay updated ?
        </h1>
        <input
          id='email'
          type='email'
          className='px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full md:w-5/12  ease-linear transition-all duration-150 mr-3 sm:mb-0 mb-2'
          placeholder='Email'
          required
          onChange={(e) => setEmail(e.target.value)}
          // name="fullName"
          value={email}
        />
        <button
          className='bg-white text-blue-900 active:bg-gray-300 text-sm font-bold uppercase px-6 py-3 rounded shadow  hover:shadow-lg outline-none hover:bg-gray-300 focus:outline-none mr-1  ease-linear transition-all duration-150'
          type='button'
          onClick={saveEmail}
        >
          Join our mailing list
        </button>
      </div>
    </section>
  );
};

export default Cta;
