import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { churchBuildingClient } from "../../lib/sanity";
import { LoadPanel } from "devextreme-react";
import ReactHlsPlayer from "react-hls-player";

const Card = (props: Record<string, any>) => {
  return (
    <div className='w-96 mx-auto rounded shadow-lg overflow-hidden'>
      <img className='w-full' src={props.imageUrl} alt={props.location} />
    </div>
  );
};

const SingleProject: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [records, setRecords] = useState<Record<string, any> | null>(null);

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      const buildingQuery = `
        *[_type == "churchBuildingProject" && _id == "${id}"] {
          _id,
          "coverImage": coverImage.asset->url,
          location,
          projectPhotos[]{
            "imageUrl": asset->url
          },
          projectVideos[] {
            "fileUrl": asset->url 
          }
        }
        `;

      const fetchProject = async () => {
        try {
          const data = await churchBuildingClient.fetch(buildingQuery);
          setIsLoading(false);
          setRecords(data[0]);
        } catch (error) {
          console.log({ error });
        }
      };

      fetchProject();
    }
  }, [id]);

  return (
    <section>
      {isLoading && (
        <div className='h-screen'>
          <LoadPanel visible showIndicator showPane />
        </div>
      )}
      {records ? (
        <section className='my-20'>
          <div className='sm:w-3/4 w-full mx-auto rounded shadow-lg overflow-hidden'>
            <div style={{ width: "100%" }} className='relative '>
              <img src={records.coverImage} alt='Project' className='w-full' />
              <div className='absolute inset-0 flex items-center justify-center'>
                <div className='bg-black bg-opacity-40 p-8 rounded'>
                  <h1 className='text-white sm:text-4xl text-base font-bold uppercase'>
                    {records.location}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className='mt-6 container px-4 mx-auto'>
            <h1 className=' text-2xl font-bold text-center'>Project Images</h1>
            <div className='flex flex-wrap gap-3'>
              {records.projectPhotos.map((re: any, index: number) => {
                if (re?.imageUrl) {
                  return (
                    <Card {...re} location={records.location} key={index} />
                  );
                }

                return null;
              })}
            </div>
          </div>

          <div className='mt-16 container px-4 mx-auto'>
            <h1 className=' text-2xl font-bold text-center mb-4'>
              Project Videos
            </h1>
            <div className='flex flex-wrap gap-3'>
              {records.projectVideos.map((re: any, index: number) => {
                if (re?.fileUrl) {
                  return (
                    <div
                      key={index}
                      className='w-96 mx-auto rounded shadow-lg overflow-hidden'
                    >
                      {/* <video src={re.fileUrl}></video> */}
                      <ReactHlsPlayer
                        url={re.fileUrl}
                        autoplay={false}
                        controls={true}
                        width='100%'
                        height='auto'
                      />
                    </div>
                  );
                }

                return null;
              })}
            </div>
          </div>
        </section>
      ) : null}
    </section>
  );
};

export default SingleProject;
