import React from "react";
import { LoadPanel } from "devextreme-react/load-panel";

export const Spinner: React.FC = () => {
  return (
    <div className="mb-40 h-screen">
      <LoadPanel
        visible={true}
        showIndicator={true}
        closeOnOutsideClick={false}
      />
    </div>
  );
};
